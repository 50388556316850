import React from "react";

const Misc_Form3 = () => {
  return (
    <>
      <div className="form3_ph1">
        Regulile jocului și modul în care îți protejăm și procesăm datele.
      </div>
      <div className="form3_ph2">
        Pentru a te înregistra pe SyncroBet, trebuie să citești, să înțelegi și
        să accepți Termenii și condițiile SyncroBet.
      </div>
      <div className="form3_ph3">
        Aceasta sectiune este in dezactivata deoarece este in proces de
        dezvoltare
      </div>
    </>
  );
};

export default Misc_Form3;
