import { faTrashAlt } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../App";
import { fromStringToArray } from "../../../Workers/Convertors";
import {
  addFavToCookies,
  checkCookie,
  CookieObjToJson,
  getCookie,
} from "../../../Workers/Helpers";
import { LotoContext } from "./../Loto";

const Misc_PlayTable_Favorites = () => {
  const context = useContext(LotoContext);
  const lang = useContext(LayoutContext);
  const [cookieExists, setCookieExists] = useState(false);

  const cookieManager = () => {
    let alreadyExists = checkCookie(context.lotteryDetails[0].lottery.name);
    if (alreadyExists === false) {
      if (context.queue[0].length > 0) {
        addFavToCookies(
          context.lotteryDetails[0].lottery.name,
          context.queue[0]
        );
      }
    } else {
      let currentValues = getCookie(context.lotteryDetails[0].lottery.name);
      currentValues += "-";
      currentValues += context.queue[0];
      console.log(currentValues);
      addFavToCookies(context.lotteryDetails[0].lottery.name, currentValues);
    }
  };
  useEffect(() => {
    if (context.lotteryDetails[0]) {
      let checkExiostence = checkCookie(context.lotteryDetails[0].lottery.name);
      if (checkExiostence === true) {
        setCookieExists(true);
      } else {
        setCookieExists(false);
      }
    }
  }, [context.lotteryDetails[0]]);

  const addFavToTicket = (favString) => {
    let favArray = fromStringToArray(favString, ",");
    context.queue[1]([]);
    let aux = [];
    favArray.map((element, key) => {
      aux[key] = parseInt(element);
    });
    context.queue[1](aux);
  };

  const removeFromFavs = (favString) => {
    let string = getCookie(context.lotteryDetails[0].lottery.name);
    if (string.charAt(0) === "-") {
      let x = string.substring(1, string.length);
      x.replace(favString, "");
      addFavToCookies(context.lotteryDetails[0].lottery.name, x);
    } else {
      let y = string.replace(favString, "");
      if (y.charAt(0) === "-") {
        y = y.substring(1, y.length);
      }
      if (y.charAt(y.length - 1) === "-") {
        y = y.substring(0, y.length - 2);
      }
      // if (y.charAt(0) != "-" && y.charAt(y.length) != "-") {
      //   let index = y.indexOf("-");
      //   y = y.replace(index, "");
      // }
      y = y.replace("--", "-");
      addFavToCookies(context.lotteryDetails[0].lottery.name, y);
    }
  };

  const renderStuff = () => {
    let string = getCookie(context.lotteryDetails[0].lottery.name);
    let array = fromStringToArray(string, "-");

    return array.map((element, key) => {
      return (
        <div className="favs_favs_wrap" key={key}>
          <div
            className="favorites_wrap"
            onClick={() => {
              addFavToTicket(element);
            }}
          >
            {fromStringToArray(element, ",").map((elem2, key2) => {
              return (
                <div className="favorite_balls" key={key2}>
                  {elem2}
                </div>
              );
            })}
          </div>
          <FontAwesomeIcon
            icon={faTrashAlt}
            className="favoriotes_trash"
            onClick={() => {
              console.log(element);
              removeFromFavs(element);
            }}
          />
        </div>
      );
    });
  };

  return (
    <div className="playtable_favs_root">
      <div className="playtable_favs_root_header">
        <span>{lang.lang[0].default.Palette_13}</span>
      </div>
      <div className="playtable_favs_sections">
        {cookieExists === false && (
          <div className="playtable_favs_label">
            {lang.lang[0].default.Palette_14}
          </div>
        )}
        {cookieExists === true && renderStuff()}
        <button
          className="playtable_favs_button"
          onClick={() => {
            cookieManager();
          }}
        >
          {lang.lang[0].default.Palette_15}
        </button>
      </div>
    </div>
  );
};

export default Misc_PlayTable_Favorites;
