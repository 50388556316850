import React, { useContext } from "react";
import { ResultsContext } from "../Pages/Results/Results";

const StuffSelector = () => {
  const consumer = useContext(ResultsContext);
  const togglerActiveStuffSelector = (index) => {
    let aux = [0, 0, 0, 0, 0, 0];
    aux[index] = 1;
    consumer.stuffSelector[1](aux);
  };

  const generateStuff = (index, value) => {
    return (
      <div
        className={
          consumer.stuffSelector[0][index] === 0
            ? "results_stuff_selector_item"
            : "results_stuff_selector_item stuff_is_active"
        }
        onClick={() => {
          togglerActiveStuffSelector(index);
        }}
      >
        {value}
      </div>
    );
  };

  return (
    <div className="stuff_selector_root">
      {generateStuff(0, "SPORT")}
      {generateStuff(1, "LIVE")}
      {generateStuff(2, "LOTTO")}
      {generateStuff(3, "SPECIALS")}
      {generateStuff(4, "SHOPS")}
      {generateStuff(5, "VIRTUALS")}
    </div>
  );
};

export default StuffSelector;
