import React, { useState } from "react";
import Misc_PlayerTickets from "./Misc_PlayerTickets";
import Misc_ProfileSide from "./Misc_ProfileSide";
import "./Profile.css";

const Profile = () => {
  const [activeItem, setActiveItem] = useState([
    0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const renderStuff = () => {
    let getActiveIdx = activeItem.indexOf(1);
    if (getActiveIdx === 2)
      return (
        <div>
          <Misc_PlayerTickets />
        </div>
      );
    else return null;
  };
  return (
    <div className="profile-root">
      <div className="profile-side-menu">
        <Misc_ProfileSide setActiveItem={setActiveItem} />
      </div>
      <div className="profile-content">{renderStuff()}</div>
    </div>
  );
};

export default Profile;
