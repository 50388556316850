import React from "react";
import Misc_Ticket_Verification from "../Loto/Ticket/Misc_Ticket_Verification";
import "./VerifyTicket.css";
const VerifyTicket = () => {
  return (
    <div className="verify_ticket_root_page">
      <div className="verify_ticket_input">
        <div className="verfitopper"></div>
        <Misc_Ticket_Verification />
      </div>
    </div>
  );
};

export default VerifyTicket;
