import React, { useContext } from "react";
import { Modal } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/fontawesome-free-solid";
import {
  convertUtcToTicketTime,
  convertUtcToTicketTimeWithMins,
} from "../../../Workers/Convertors";
import { LayoutContext } from "../../../App";
const ModalVerify = (props) => {
  var Barcode = require("react-barcode");

  const lang = useContext(LayoutContext);

  const findInSelected = (element, arr) => {
    if (arr.indexOf(element) != -1) return 1;
    return 0;
  };

  const generateNumbersList = () => {
    return props.response?.content.selection.split(",").map((element, key) => {
      if (props.response.status === 0 || props.response.status === 1) {
        return (
          <div className="ticket_ticket_ball" key={key}>
            {findInSelected(
              element,
              props.response.content?.drawNumbers.split(",")
            ) == 0 ? (
              <span style={{ backgroundColor: "red", border: "1px solid red" }}>
                {element}
              </span>
            ) : (
              <span
                style={{ backgroundColor: "green", border: "1px solid green" }}
              >
                {element}
              </span>
            )}
          </div>
        );
      } else
        return (
          <div className="ticket_ticket_ball" key={key}>
            <span>{element}</span>
          </div>
        );
    });
  };

  const generateDrawnList = () => {
    return props.response?.content.drawNumbers
      .split(",")
      .map((element, key) => {
        return (
          <div className="ticket_ticket_ball" key={key}>
            {findInSelected(
              element,
              props.response.content?.selection.split(",")
            ) === 1 ? (
              <span
                style={{
                  backgroundColor: "green",
                  border: "1px solid green",
                }}
              >
                {element}
              </span>
            ) : (
              <span>{element}</span>
            )}
          </div>
        );
      });
  };

  return (
    <Modal open={props.open} onClose={props.onclose} className="modal-ticket">
      <div className="modal-container">
        <div className="modal-ticket-header">
          <div className="modal-ticket-header-title">
            {lang.lang[0].default.Verif_1}
          </div>
          <FontAwesomeIcon
            icon={faTimes}
            className="modal-ticket-header-icon"
            onClick={props.onclose}
          />
        </div>
        <div className="modal-ticket-subheader">
          <div className="modal-ticket-subheader-numbers">
            {lang.lang[0].default.Verif_2}:
            <span>{props.response.content?.selection.split(",").length}</span>
          </div>
          <div className="modal-ticket-subheader-cota">
            {lang.lang[0].default.Verif_3}:<span>{props.response?.odds}</span>
          </div>
        </div>
        <div className="modal-ticket-content">
          <div className="modal-ticket-left-wrap">
            <div className="modal-ticket-left">
              <div className="modal-ticket-date-time">
                {convertUtcToTicketTimeWithMins(
                  props.response.content?.drawTime
                )}
              </div>
              <div className="modal-ticket-name">
                {props.response.content?.lottery}
              </div>
              <div style={{ borderBottom: "1px dashed black" }}>
                {props.response?.status != 2 &&
                  props.response?.status != 3 &&
                  generateDrawnList()}
              </div>
              <div className="modal-ticket-name">
                {lang.lang[0].default.Verif_4}:
              </div>
              <div>{generateNumbersList()}</div>
            </div>
          </div>

          <div className="modal-ticket-right">
            <div
              style={{
                fontSize: "10px",
                color: "darkgrey",
                textAlign: "center",
              }}
            >
              <div className="modal-ticket-serial">
                <Barcode
                  value={props.response?.header?.serial}
                  format="CODE128"
                  textPosition="top"
                  textMargin={6}
                />
                <div style={{ fontSize: "12px", fontWeight: "300" }}>
                  {convertUtcToTicketTimeWithMins(props.response?.header?.date)}
                </div>
              </div>
            </div>
            <div className="modal-ticket-mize">
              <div className="modal-ticket-mize-ct">
                <span
                  className="modal-ticket-mize-ct-ct"
                  style={{ fontWeight: "300" }}
                >
                  {lang.lang[0].default.Verif_3}:
                </span>
                <span className="modal-ticket-mize-ct-num">
                  {props.response?.odds}
                </span>
              </div>

              <div className="modal-ticket-mize-miza">
                <span
                  className="modal-ticket-mize-ct-ct"
                  style={{ fontWeight: "300" }}
                >
                  {lang.lang[0].default.Verif_5}:
                </span>
                <span className="modal-ticket-mize-ct-num">
                  {props.response?.content?.system}
                </span>
              </div>

              <div className="modal-ticket-mize-miza">
                <span
                  className="modal-ticket-mize-ct-ct"
                  style={{ fontWeight: "300" }}
                >
                  {lang.lang[0].default.Verif_6}:
                </span>
                <span className="modal-ticket-mize-ct-num">
                  {props.response?.header?.paid?.gross}
                  <span> {props.response?.header?.ccy}</span>
                </span>
              </div>

              {props.response?.header?.paid?.tax != null && (
                <div className="modal-ticket-mize-taxa">
                  <span
                    className="modal-ticket-mize-ct-ct"
                    style={{ fontWeight: "300" }}
                  >
                    CDA TAX:
                  </span>
                  <span className="modal-ticket-mize-ct-num">
                    {props.response?.header?.paid?.tax}
                    <span>{props.response?.header?.ccy}</span>
                  </span>
                </div>
              )}
              <div className="modal-ticket-mize-mizataxa">
                <span
                  className="modal-ticket-mize-ct-ct"
                  style={{ fontWeight: "300" }}
                >
                  {lang.lang[0].default.Verif_7}:
                </span>
                <span className="modal-ticket-mize-ct-num">
                  {props.response?.header?.paid?.net}
                  <span> {props.response?.header?.ccy}</span>
                </span>
              </div>

              {props.response.minWin != null ? (
                props.response.minWin ? (
                  <div className="modal-ticket-mize-mizataxa">
                    <span
                      className="modal-ticket-mize-ct-ct"
                      style={{ fontWeight: "300" }}
                    >
                      Minimum Win:
                    </span>
                    <span className="modal-ticket-mize-ct-num">
                      {props.response.minWin}{" "}
                      <span>{props.response?.header?.ccy}</span>
                    </span>
                  </div>
                ) : null
              ) : null}
            </div>
            <div className="modal-ticket-buttons">
              {props.response?.maxWin && (
                <div className="modal-ticket-pot-win">
                  <div className="modal-ticket-pot-win-lbl">MAX WIN:</div>
                  <div className="modal-ticket-pot-win-win">
                    {props.response?.maxWin}
                    <span>{props.response?.header?.ccy}</span>
                  </div>
                </div>
              )}
              {props.response?.wins && (
                <div
                  className="modal-ticket-pot-win"
                  style={{ color: "green" }}
                >
                  <div className="modal-ticket-pot-win-lbl">
                    {lang.lang[0].default.Verif_8}:
                  </div>
                  <div className="modal-ticket-pot-win-win">
                    {props.response?.wins?.totalWin}
                    <span style={{ color: "green", marginLeft: "2px" }}>
                      {props.response?.header?.ccy}
                    </span>
                  </div>
                </div>
              )}
              {props.response?.status === 0 && (
                <div lassName="modal-ticket-pot-win" style={{ color: "red" }}>
                  <div
                    className="modal-ticket-pot-win-lbl"
                    style={{
                      textAlign: "center",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    {lang.lang[0].default.Verif_11}
                  </div>
                </div>
              )}
              <div className="modal-tickets-btns">
                <button
                  className="modal-ticket-close-btn"
                  onClick={props.onclose}
                >
                  {lang.lang[0].default.Verif_9}
                </button>
                <button
                  className="modal-ticket-print-btn"
                  onClick={() => {
                    let xxx = document.querySelector(
                      "body > div.modal-ticket > div.modal-container > div.modal-ticket-content > div.modal-ticket-right > div:nth-child(1) > div > svg > rect"
                    );
                    xxx.style.cssText = "fill: white !important";
                    let yyy = document.querySelector(
                      "body > div.modal-ticket > div.modal-container > div.modal-ticket-content > div.modal-ticket-right > div:nth-child(1) > div > svg > g"
                    );
                    yyy.style.cssText = "fill: black !important";
                    window.print();
                  }}
                >
                  {lang.lang[0].default.Verif_10}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalVerify;
