export const DateToDdMmYy = (utcDate) => {
  let xxx = new Date(utcDate);
  let day = xxx.getDate() > 9 ? xxx.getDate() : "0" + xxx.getDate();
  let month =
    xxx.getMonth() + 1 > 9 ? xxx.getMonth() + 1 : "0" + (xxx.getMonth() + 1);

  return day + "." + month + "." + utcDate.getUTCFullYear();
};

export const DateToHhMm = (utcDate) => {
  let xxx = new Date(utcDate);
  let hour = xxx.getHours() > 9 ? xxx.getHours() : "0" + xxx.getHours();
  let mins = xxx.getMinutes() > 9 ? xxx.getMinutes() : "0" + xxx.getMinutes();
  return hour + ":" + mins;
};

export const fromStringToArray = (string, separator) => {
  let array = string.split(separator);
  return array;
};

export const convertUtcToSingleRowTime = (utcString) => {
  let ddd = new Date(utcString);
  let day =
    ddd.toLocaleString("EN-en", { weekday: "short" }).charAt(0).toUpperCase() +
    ddd.toLocaleString("EN-en", { weekday: "short" }).slice(1);
  let toLocaleTime = ddd.toLocaleTimeString();
  let hour = toLocaleTime.includes("AM")
    ? toLocaleTime.length === 11
      ? toLocaleTime.slice(0, 5)
      : "0" + toLocaleTime.slice(0, 4)
    : toLocaleTime.length === 11
    ? (parseInt(toLocaleTime.slice(0, 2)) + 12).toString() +
      toLocaleTime.slice(2, 5)
    : (parseInt(toLocaleTime.slice(0, 1)) + 12).toString() +
      toLocaleTime.slice(1, 4);
  return day + " " + hour;
};

export const convertUtcToTicketTime = (utcString) => {
  let ddd = new Date(utcString);
  let day =
    ddd.toLocaleString("EN-en", { weekday: "short" }).charAt(0).toUpperCase() +
    ddd.toLocaleString("EN-en", { weekday: "short" }).slice(1);
  let dayNum = ddd.getDate() > 9 ? ddd.getDate() : "0" + ddd.getDate();
  let month =
    ddd.getMonth() + 1 > 9 ? ddd.getMonth() + 1 : "0" + (ddd.getMonth() + 1);
  return day + " " + dayNum + "." + month;
};

export const convertUtcToTicketTimeWithMins = (utcString) => {
  let ddd = new Date(utcString);
  let day =
    ddd.toLocaleString("EN-en", { weekday: "short" }).charAt(0).toUpperCase() +
    ddd.toLocaleString("EN-en", { weekday: "short" }).slice(1);
  let dayNum = ddd.getDate() > 9 ? ddd.getDate() : "0" + ddd.getDate();
  let month =
    ddd.getMonth() + 1 > 9 ? ddd.getMonth() + 1 : "0" + (ddd.getMonth() + 1);
  let hour = ddd.getHours() > 9 ? ddd.getHours() : "0" + ddd.getHours();
  let mins = ddd.getMinutes() > 9 ? ddd.getMinutes() : "0" + ddd.getMinutes();
  return day + " " + dayNum + "." + month + " - " + hour + ":" + mins;
};

export const textFormatter = (string, length) => {
  if (string.length < length) {
    return string;
  } else {
    return string.substr(0, length - 2) + "...";
  }
};
