import React, { useContext, useEffect, useState } from "react";
import Misc_ContentResults_List from "./Content/Misc_ContentResults_List";
import Misc_ContentResults_Miniheader from "./Content/Misc_ContentResults_Miniheader";
import { ResultsContext } from "./Results";

const ContentResults = (props) => {
  const consumer = useContext(ResultsContext);



  return (
    consumer.stuffSelector[0].indexOf(1) === 2 && (
      <div className="content_results_root">
        <div className="content_results_miniheader">
          <Misc_ContentResults_Miniheader />
        </div>
        <div className="content_results_list">
          <Misc_ContentResults_List
            lotteries={consumer.resultsDailyContent[0]}
          />
        </div>
      </div>
    )
  );
};

export default ContentResults;
