import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Misc_NavMenu = (props) => {
  return (
    props.lang != null && (
      <>
        <div className="nav_menu_item">
          <NavLink to="/loto" activeClassName="is_active" exact>
            {props.lang.header_1}
          </NavLink>
        </div>
        <div className="nav_menu_item">
          <NavLink to="/rezultate" activeClassName="is_active" exact>
            {props.lang.header_2}
          </NavLink>
        </div>
      </>
    )
  );
};

export default Misc_NavMenu;
