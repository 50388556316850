import React from "react";
import {
  convertUtcToTicketTime,
  convertUtcToTicketTimeWithMins,
  fromStringToArray,
} from "../../../Workers/Convertors";
import { addOrRemoveNumber } from "../../../Workers/Helpers";

const Misc_Ticket_Numbers = (props) => {
  const generateNumbersList = () => {
    return props.queue.map((element, key) => {
      return (
        <div className="ticket_ticket_ball" key={key}>
          <span
            onClick={(e) => {
              addOrRemoveNumber(props.queue, element, props.setQueue, e);
            }}
          >
            {element}
          </span>
        </div>
      );
    });
  };

  let drawBalls = props.lotteryDetails
    ? fromStringToArray(props.lotteryDetails.lottery.system, "/")[0]
    : null;
  let ballsCount = props.lotteryDetails
    ? fromStringToArray(props.lotteryDetails.lottery.system, "/")[1]
    : null;

  return (
    <div className="ticket_numbers_root">
      <div className="ticket_time">
        {props.lotteryDetails
          ? convertUtcToTicketTimeWithMins(props.lotteryDetails.scheduleTime)
          : null}
      </div>
      <div className="ticket_numbers_header">
        <div className="ticket_numbers_header_name">
          {props.lotteryDetails ? props.lotteryDetails.lottery.name : null}
        </div>
        <div className="ticket_numbers_header_ballSystem">
          <div className="ticket_numbers_guess">{drawBalls}</div>
          <div className="ticket_numbers_outof">{ballsCount}</div>
        </div>
      </div>
      <div className="ticket_numbers_list">{generateNumbersList()}</div>
    </div>
  );
};

export default Misc_Ticket_Numbers;
