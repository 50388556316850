import React, { useContext, useEffect, useState } from "react";
import { GetScheduleContent } from "../../../Workers/API_Manager";
import {
  convertUtcToSingleRowTime,
  fromStringToArray,
} from "../../../Workers/Convertors";
import { LotoContext } from "../Loto";
import Misc_PlayTable from "./Misc_PlayTable";

const Misc_Content_Single_Row = (props) => {
  const consumer = useContext(LotoContext);

  const [playtoggle, setPlaytoggle] = useState(true);

  const [descTimer, setDescTimer] = useState(null);
  const [toSendTimer, setToSendTimer] = useState(null);

  useEffect(() => {
    setDescTimer(null);
    const tttime =
      consumer.lotteryDetails[0] != null &&
      setInterval(() => {
        let curentDate = new Date();
        let lotoDate = new Date(consumer.lotteryDetails[0].closeTime);
        setDescTimer(lotoDate.getTime() - curentDate.getTime());
      }, 1000);
    return () => clearInterval(tttime);
  }, [consumer.lotteryDetails[0]]);

  useEffect(() => {
    setToSendTimer(descTimer);
  }, [descTimer]);

  const toggleplay = () => setPlaytoggle(!playtoggle);

  const generateOdd = (index) => {
    return (
      <div className="content_single_row_odd">
        {fromStringToArray(props.lottery.odds, "|")[index]
          ? fromStringToArray(props.lottery.odds, "|")[index]
          : "-"}
      </div>
    );
  };

  const checkLotteryDate = (date) => {
    let dt = new Date();
    let lotoDate = new Date(date);
    if (lotoDate < dt) return false;
    return true;
  };

  return (
    checkLotteryDate(props.lottery.closeTime) && (
      <div
        className="content_single_row_root"
        onClick={(e) => {
          GetScheduleContent(
            props.lottery.id,
            consumer.lotteryDetails[1],
            consumer.selectedLotteryDate[1]
          );
          consumer.selectedLotteryId[1](props.lottery.id);
          setPlaytoggle(true);
        }}
      >
        <div className="content_single_row_date">
          {convertUtcToSingleRowTime(props.lottery.date)}
        </div>
        <div className="content_single_row_shopCode">{props.lottery.code}</div>
        <div className="content_single_row_name">
          {props.lottery.lotteryName}
        </div>
        <div className="content_single_row_system">
          <div className="content_row_system_draw">
            {fromStringToArray(props.lottery.system, "/")[0]}
          </div>
          <div className="content_row_system_outOf">
            {fromStringToArray(props.lottery.system, "/")[1]}
          </div>
        </div>
        <div className="content_single_row_odds">
          {generateOdd(0)}
          {generateOdd(1)}
          {generateOdd(2)}
          {generateOdd(3)}
          {generateOdd(4)}
          {generateOdd(5)}
          {generateOdd(6)}
          {generateOdd(7)}
        </div>
        {props.openId === props.currentId && playtoggle ? (
          <div className="playtable">
            <Misc_PlayTable
              toggleplay={toggleplay}
              playtoggle={playtoggle}
              timer={toSendTimer}
            />
          </div>
        ) : null}
      </div>
    )
  );
};

export default Misc_Content_Single_Row;
