import React, { useContext, useState } from "react";
import { LayoutContext } from "../../App";
import Misc_FormFields from "./Misc_FormFields";
import { RegisterContext } from "./Register";

const Misc_Form1 = () => {
  const consumer = useContext(RegisterContext);
  const lang = useContext(LayoutContext);
  const fieldVerifyer = (toVerify, condition, setter) => {
    if (toVerify.length < condition) {
      return setter(true);
    }
    return setter(false);
  };

  const validateEmail = (email, setter) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) return setter(false);
    return setter(true);
  };

  const handleChange = (setter, newValue) => {
    setter(newValue);
  };

  return (
    <>
      <div className="form1_title">
        <span>{lang.lang[0].default.Reg_1}</span>
        <span className="form1_title_mins">~ 2min</span>
      </div>
      <div className="form1_basics">
        <div className="form1_basics_title">{lang.lang[0].default.Reg_2}</div>
        <Misc_FormFields
          inputLabel={lang.lang[0].default.Reg_3}
          handleChange={handleChange}
          fieldValue={consumer.prenume[0]}
          fieldConstrain={1}
          fieldSetter={consumer.prenume[1]}
          errorMessage={lang.lang[0].default.Reg_9 + " 2"}
          fieldVerifyer={fieldVerifyer}
        />
        <br />
        <Misc_FormFields
          inputLabel={lang.lang[0].default.Reg_4}
          handleChange={handleChange}
          fieldValue={consumer.nume[0]}
          fieldConstrain={1}
          fieldSetter={consumer.nume[1]}
          errorMessage={lang.lang[0].default.Reg_9 + " 2"}
          fieldVerifyer={fieldVerifyer}
        />
        <br />
        <br />
        <br />
        <br />
        <Misc_FormFields
          inputLabel={lang.lang[0].default.Reg_5}
          handleChange={handleChange}
          fieldValue={consumer.email[0]}
          fieldConstrain={1}
          fieldSetter={consumer.email[1]}
          errorMessage={lang.lang[0].default.Reg_10}
          fieldVerifyer={validateEmail}
        />
        <br />
        <Misc_FormFields
          inputLabel={lang.lang[0].default.Reg_6}
          handleChange={handleChange}
          fieldValue={consumer.user[0]}
          fieldConstrain={3}
          fieldSetter={consumer.user[1]}
          errorMessage={lang.lang[0].default.Reg_9 + " 4"}
          fieldVerifyer={fieldVerifyer}
        />
        <br />
        <Misc_FormFields
          inputLabel={lang.lang[0].default.Reg_7}
          handleChange={handleChange}
          fieldValue={consumer.password[0]}
          fieldConstrain={4}
          fieldSetter={consumer.password[1]}
          errorMessage={lang.lang[0].default.Reg_9 + " 5"}
          fieldVerifyer={fieldVerifyer}
          type="password"
        />
      </div>
    </>
  );
};

export default Misc_Form1;
