import axios from "axios";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../App";

const get_daily_countries = process.env.REACT_APP_GET_DAILY_COUNTRIES;
const get_daily_systems = process.env.REACT_APP_GET_DAILY_SYSTEMS;
const get_daily_topidx = process.env.REACT_APP_GET_DAILY_TOPIDX;
const get_index_country = process.env.REACT_APP_GET_INDEX_COUNTRY;

const get_content_bysystems = process.env.REACT_APP_GET_CONTENT_BYSYSTEM;
const get_content_bycountry = process.env.REACT_APP_GET_CONTENT_BYCOUNTRY;
const get_content_bylottery = process.env.REACT_APP_GET_CONTENT_BYLOTTERY;
const get_scheduled_item = process.env.REACT_APP_GET_SCHEDULED_INFO;

const get_results_topidx = process.env.REACT_APP_GET_RESULTS_TOPIDX;
const get_results_daily_countries =
  process.env.REACT_APP_GET_RESULTS_DAILY_COUNTRIES;
const get_results_daily_systems =
  process.env.REACT_APP_GET_RESULTS_DAILY_SYSTEMS;
const get_results_content_bycountry =
  process.env.REACT_APP_GET_RESULTS_CONTENT_BYCOUNTRY;
const get_results_content_bysystems =
  process.env.REACT_APP_GET_RESULTS_CONTENT_BYSYSTEM;
const get_results_content_bylottery =
  process.env.REACT_APP_GET_RESULTS_CONTENT_BYLOTTERY;
const get_results_index_country =
  process.env.REACT_APP_GET_RESULTS_INDEX_COUNTRY;


export const GetDailyCountries = async (tkn, daySelected, setAllLotteries) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr-FR" },
  };
  await axios
    .get(get_daily_countries + `${daySelected}`, config)
    .then((response) => {
      setAllLotteries(response.data);
    });
};

export const GetDailySystems = async (
  tkn,
  daySelected,
  setAllLotteriesSystem
) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_daily_systems + `${daySelected}`,
    config
  );
  setAllLotteriesSystem(response.data);
};

export const GetDailyTopIdx = async (tkn, daySelected, setTopIdxLotteries) => {
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr-FR" },
  };
  const response = await axios.get(get_daily_topidx + `${daySelected}`, config);
  setTopIdxLotteries(response.data);
};

export const GetDailyIndexCountry = async (
  countryCode,
  day,
  setSubMenuItems
) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_index_country + `${day}` + `&countryCode=` + `${countryCode}`,
    config
  );
  setSubMenuItems(response.data);
};

export const GetContentBySystem = async (day, systems, setter) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_content_bysystems + `${day}` + "&systems=" + `${systems}`,
    config
  );
  setter(response.data);
};

export const GetContentByCountry = async (day, countryCode, setter) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}`, "Accept-Language": "fr" },
  };
  const response = await axios.get(
    get_content_bycountry + `${day}` + "&countryCode=" + `${countryCode}`,
    config
  );
  setter(response.data);
};

export const GetContentByLottery = async (day, lotteryId, setter) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_content_bylottery + `${day}` + "&lotteryId=" + `${lotteryId}`,
    config
  );
  setter(response.data);
};

export const GetScheduleContent = async (
  scheduledId,
  setContentLotery,
  setSelectedLotteryDate
) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_scheduled_item + `${scheduledId}`,
    config
  );
  setContentLotery(response.data);
  setSelectedLotteryDate(response.data.closeTime);
};

export const PostRequestCalculate = async (
  lotteryId,
  playedAmount,
  numbers,
  checkboxes,
  setter
) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const toSend = {
    lotteryId: lotteryId,
    playedAmount: playedAmount,
    numbers: numbers,
    system: checkboxes,
  };
  await axios
    .post(process.env.REACT_APP_POST_TICKET_CALCULATOR, toSend, config)
    .then((response) => {
      setter(response.data);
    });
};

export const BookTicket = async (json, setter) => {
  const tknLog = localStorage.getItem("_tknLog");
  const tkn = localStorage.getItem("_tkn");
  let finalTkn = tknLog != null ? tknLog : tkn;
  const config = {
    headers: { Authorization: `Bearer ${finalTkn}` },
  };
  await axios
    .post(process.env.REACT_APP_BOOK_TICKET, json, config)
    .then((response) => {
      setter(response.data);
    });
};

export const GetResultsTopIdx = async (dayBefore, setTopIdxLotteries) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(get_results_topidx + `${dayBefore}`, config);
  setTopIdxLotteries(response.data);
};

export const GetResultsDailyCountries = async (dayBefore, setAllLotteries) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_results_daily_countries + `${dayBefore}`,
    config
  );
  setAllLotteries(response.data);
};

export const GetResultsDailySystems = async (dayBefore, setAllLotteries) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_results_daily_systems + `${dayBefore}`,
    config
  );
  setAllLotteries(response.data);
};

export const GetResultsContentByCoutry = async (
  dayBefore,
  countryCode,
  setter
) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_results_content_bycountry +
      `${dayBefore}` +
      "&countryCode=" +
      `${countryCode}`,
    config
  );
  setter(response.data);
};

export const GetResultsContentByLottery = async (
  dayBefore,
  lotteryId,
  setter
) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_results_content_bylottery +
      `${dayBefore}` +
      "&lotteryId=" +
      `${lotteryId}`,
    config
  );
  setter(response.data);
};

export const GetResultsContentBySystem = async (
  dayBefore,
  systemArr,
  setter
) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_results_content_bysystems +
      `${dayBefore}` +
      "&systems=" +
      `${systemArr}`,
    config
  );
  setter(response.data);
};
export const GetResultsDailyIndexCountry = async (
  dayBefore,
  countryCode,
  setSubMenuItems
) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  const response = await axios.get(
    get_results_index_country +
      `${dayBefore}` +
      `&countryCode=` +
      `${countryCode}`,
    config
  );
  setSubMenuItems(response.data);
};
export const VerifyTicketPost = async (ticketCode, setter) => {
  const tkn = localStorage.getItem("_tkn");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
    headers: { "Access-Control-Allow-Origin": "*" },
  };

  await axios
    .get(process.env.REACT_APP_VERIFY_TICKET + `${ticketCode}`, config)
    .then((response) => {
      setter(response.data);
    });
};
export const GetCookieApi = async (cookieKey, setter) => {
  const tkn = localStorage.getItem("_tknLog");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  await axios
    .get(process.env.REACT_APP_GET_COOKIE + `${cookieKey}`, config)
    .then((response) => {
      setter(response.data);
    });
};

export const PostCookieApi = async (cookieKey, cookieValue) => {
  const tkn = localStorage.getItem("_tknLog");
  const config = {
    headers: { Authorization: `Bearer ${tkn}` },
  };
  await axios.post(
    process.env.REACT_APP_POST_COOKIE +
      `${cookieKey}` +
      "&value=" +
      `${cookieValue}`,
    null,
    config
  );
};

export const OldEveryMatrixAuth = async (token) => {
  const config = {
    headers: { "Access-Control-Allow-Origin": "*" },
  };

  const data = {
    LaunchToken: token,
    RequestScope: "country, age",
  };

  console.log(data);
  // await axios
  //   .post(process.env.REACT_APP_EVERYMATRIX_AUTH, { data }, config)
  //   .then((response) => console.log(response.data));

  await fetch(
    "https://cors-anywhere.herokuapp.com/" +
      process.env.REACT_APP_EVERYMATRIX_AUTH,
    {
      method: "POST",
      mode: "cors",
      headers: {
        //"Content-Type": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(data),
    }
  )
    .then((res) => {
      res.json();
    })
    .then((data) => console.log(data));
};

export const EveryMatrix_GetBalance = async () => {
  const data = {
    Token: "3898507_6_x9gstdI92EmxW1RoLTN0lQ",
    Currency: "EUR",
    Hash: "rjgrlt345", //?? sau altul
  };

  // await axios
  //   .post(process.env.REACT_APP_EVERYMATRIX_GETBALANCE, data, null)
  //   .then((response) => console.log(response));
  await fetch(process.env.REACT_APP_EVERYMATRIX_GETBALANCE, {
    method: "POST",
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((data) => console.log(data));
};

export const EveryMatrixAuth = async (
  token,
  setter,
  setterToken,
  setterUsername,
  setterUserId,
  setterCcy
) => {
  await axios
    .get(process.env.REACT_APP_EVERYMATRIX_AUTH + `${token}`, null)
    .then((response) => {
      setter(response.data);
      setterToken(response.data.token);
      setterUsername(response.data.userName);
      setterUserId(response.data.userId);
      setterCcy(response.data.currency);
    });
};
