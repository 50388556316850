import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/fontawesome-free-solid";
import "./NavigationGrid.css";
import "./Navigation.css";
import Misc_NavMenu from "./Misc_NavMenu";
import Misc_Date from "./Misc_Date";
import Misc_Time from "./Misc_Time";
import Misc_LoginButton from "./Misc_LoginButton";
import Misc_RegisterButton from "./Misc_RegisterButton";
import ModalLogin from "./ModalLogin";
import { LoggToken } from "../Workers/TokenProcessor";
import { NavLink } from "react-router-dom";
import * as fr from "../../src/langs/fr.json";
import * as en from "../../src/langs/en.json";
import { LayoutContext } from "../App";

const Navigation = () => {
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [user, setUser] = useState(null);
  const [pass, setPass] = useState(null);

  const [errorMessage, setErrorMessage] = useState(null);

  const handleUserChange = (user) => setUser(user);
  const handlePassChange = (pass) => setPass(pass);

  const handleModalOpen = () => setOpenLoginModal(true);
  const handleModalClose = () => setOpenLoginModal(false);

  const consumer = useContext(LayoutContext);

  let _anonTkn;
  useEffect(() => {
    _anonTkn =
      localStorage.getItem("_tkn") != null
        ? localStorage.getItem("_tkn")
        : null;
  }, []);

  const handleLoginHere = () => {
    Promise.resolve(LoggToken(user, pass)).then((res) => {
      if (res.status) {
        sessionStorage.setItem("username", user);
        sessionStorage.setItem("_isLogged", true);
        handleModalClose();
      } else {
        setErrorMessage("Login failed. Verify your credentials.");
      }
    });
  };

  const [togMenu, setTogMenu] = useState(false);
  const toggleMenu = () => setTogMenu(!togMenu);

  useEffect(() => {
    let theinterval = setInterval(() => {
      setErrorMessage(null);
    }, 5000);
    return () => clearInterval(theinterval);
  }, [errorMessage]);

  return (
    consumer.lang[0] && (
      <div className="navigation_root">
        <div
          className="nav_more"
          onClick={() => {
            toggleMenu();
          }}
        >
          <FontAwesomeIcon className="nav_more_icon" icon={faList} />
        </div>
        <div className="nav_logo">
          <NavLink to="/">
            <img src="/logo_nge.png" alt="Placeholder" />
          </NavLink>
        </div>
        <div className="nav_menu">
          <Misc_NavMenu lang={consumer.lang[0].default} />
        </div>
        <div className="nav_date">
          <Misc_Date />
        </div>
        <div className="nav_time">
          <Misc_Time />
        </div>
        {!sessionStorage.getItem("_isLogged") && (
          <div
            className="nav_login"
            onClick={() => {
              handleModalOpen();
            }}
          >
            <Misc_LoginButton lang={consumer.lang[0].default} />
          </div>
        )}
        {!sessionStorage.getItem("_isLogged") && (
          <NavLink to="/register">
            <div className="nav_register">
              <Misc_RegisterButton lang={consumer.lang[0].default} />
            </div>
          </NavLink>
        )}
        {sessionStorage.getItem("_isLogged") && (
          <NavLink className="user_badge_wrap" exact to="/profile">
            <div className="user_badge_user">
              {sessionStorage.getItem("username")}
            </div>
            <div className="user_badge_balance">
              4.20 {consumer.lang[0].default.Ticket_6}
            </div>
          </NavLink>
        )}
        <ModalLogin
          open={openLoginModal}
          onclose={handleModalClose}
          onopen={handleModalOpen}
          user={user}
          pass={pass}
          handleUserChange={handleUserChange}
          handlePassChange={handlePassChange}
          handleLoginHere={handleLoginHere}
          errorMessage={errorMessage}
        />
        {togMenu && (
          <div
            className="more_menu"
            onClick={(e) => {
              if (e.target === e.currentTarget) setTogMenu(false);
            }}
          >
            <div className="more_mobile_wrapper">
              <div>
                <NavLink
                  to="/loto"
                  activeClassName="is_mob_active"
                  exact
                  onClick={() => {
                    setTogMenu(false);
                  }}
                >
                  {consumer.lang[0].default.header_1}
                </NavLink>
              </div>
              <div className="bottom_brd">
                <NavLink
                  to="/rezultate"
                  activeClassName="is_mob_active"
                  exact
                  onClick={() => {
                    setTogMenu(false);
                  }}
                >
                  {consumer.lang[0].default.header_2}
                </NavLink>
              </div>
              <div>
                <NavLink
                  to="/verifyTicket"
                  activeClassName="is_mob_active"
                  exact
                  onClick={() => {
                    setTogMenu(false);
                  }}
                >
                  {consumer.lang[0].default.Verify_1}
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default Navigation;
