import React, {
  useContext,
  useEffect,
  useState,
} from "react";
import Misc_ContentList from "./Content/Misc_ContentList";
import Misc_Content_Miniheader from "./Content/Misc_Content_Miniheader";
import { LotoContext } from "./Loto";

const Content = (props) => {
  const consumer = useContext(LotoContext);
  const [openId, setOpenId] = useState("");
  const toggleOpenId = (index) => {
    if (index === openId) setOpenId(null);
    setOpenId(index);
  };

  useEffect(() => {
    setOpenId("");
  }, [consumer.dailyContent[0]]);

  return (
    <div className="content_root">
      <div className="content_mini_header">
        <Misc_Content_Miniheader />
      </div>
      <div className="content_list">
        <Misc_ContentList
          lotteries={consumer.dailyContent[0]}
          openId={openId}
          toggleOpenId={toggleOpenId}
        />
      </div>
    </div>
  );
};

export default Content;
