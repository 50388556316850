export const getCurrentDaysArr = (language) => {
  const d = new Date();
  let shortDaysArr = [];
  let dayNmonth = [];
  for (let i = 0; i < 8; i++) {
    let objShort =
      d.toLocaleString(language, { weekday: "short" }).charAt(0).toUpperCase() +
      d.toLocaleString(language, { weekday: "short" }).slice(1);
    // if (objShort.length > 3) objShort = objShort.slice(0, -1);
    shortDaysArr.push(objShort);
    let obj = {
      day: d.getDate() > 9 ? d.getDate() : "0" + d.getDate(),
      month: d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1),
    };
    d.setDate(d.getDate() + 1);
    dayNmonth.push(obj);
  }
  let toReturn = {
    shortDays: shortDaysArr,
    dayNmonth: dayNmonth,
  };
  return toReturn;
};
export const getCurrentDaysArrResults = (language) => {
  const d = new Date();
  let shortDaysArr = [];
  let dayNmonth = [];
  for (let i = 0; i < 8; i++) {
    let objShort =
      d.toLocaleString(language, { weekday: "short" }).charAt(0).toUpperCase() +
      d.toLocaleString(language, { weekday: "short" }).slice(1);
    // if (objShort.length > 3) objShort = objShort.slice(0, -1);
    shortDaysArr.push(objShort);
    let obj = {
      day: d.getDate() > 9 ? d.getDate() : "0" + d.getDate(),
      month: d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1),
    };
    d.setDate(d.getDate() - 1);
    dayNmonth.push(obj);
  }
  let toReturn = {
    shortDays: shortDaysArr,
    dayNmonth: dayNmonth,
  };
  return toReturn;
};

export const createFlag = (countryCode) => {
  var toReturn = "FlagsAssets/" + countryCode?.toLowerCase() + ".png";
  return toReturn;
};

export const addOrRemoveNumber = (queue, nombre, setQueue, e, maxBetNumber) => {
  let auxQueue = [];
  let isAlreadyInQueue = false;
  let indexOfFound = 0;
  e.stopPropagation();
  if (queue.length > 0) {
    for (let i = 0; i < queue.length; i++) {
      if (queue[i] === nombre) {
        isAlreadyInQueue = true;
        indexOfFound = i;
      }
    }
  }
  if (isAlreadyInQueue === false) {
    if (queue.length < maxBetNumber) {
      auxQueue = [...queue];
      auxQueue.push(nombre);
      setQueue(auxQueue);
    }
  } else if (isAlreadyInQueue === true) {
    let auxMF = [...queue];
    auxMF.splice(indexOfFound, 1);
    setQueue(auxMF);
  }
};

export const createJsonBody = (
  queue,
  lotteryId,
  betAmount,
  scheduleId,
  combinationsString,
  betSystem,
  setJsonBody,
  emToken,
  emUsername,
  emUserId,
  emCcy
) => {
  let token = emToken != null ? emToken : "";
  let username = emUsername != null ? emUsername : "";
  let userId = emUserId != null ? emUserId : "";
  let ccy = emCcy != null ? emCcy : "";

  let toSendwithToken = {
    lotteryId: lotteryId,
    betAmount: betAmount,
    currency: ccy,
    scheduleId: scheduleId,
    numbersBet: {
      numbers: queue.join(),
      system:
        combinationsString.length > 0
          ? combinationsString.join()
          : queue.length <= betSystem
          ? queue.length
          : betSystem,
    },
    playerInfo: {
      deviceInfo: DeviceInfo(),
      userAgent: UserAgent(),
      ipAdress: Promise.resolve(GetIpAdress()),
      location: {
        latitude: 0,
        longitude: 0,
      },
      timeZoneOffset: 0,
    },
    externalSource: {
      token: token,
      userId: userId,
      userName: username,
    },
  };

  let toSend = {
    lotteryId: lotteryId,
    betAmount: betAmount,
    scheduleId: scheduleId,
    numbersBet: {
      numbers: queue.join(),
      system:
        combinationsString.length > 0
          ? combinationsString.join()
          : queue.length <= betSystem
          ? queue.length
          : betSystem,
    },
    playerInfo: {
      deviceInfo: DeviceInfo(),
      userAgent: UserAgent(),
      ipAdress: Promise.resolve(GetIpAdress()),
      location: {
        latitude: 0,
        longitude: 0,
      },
      timeZoneOffset: 0,
    },
  };
  if (emToken != null) {
    setJsonBody(toSendwithToken);
  } else {
    setJsonBody(toSend);
  }
};
export const DeviceInfo = () => {
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(
    navigator.userAgent
  );
};
export const UserAgent = () => {
  return navigator.userAgent;
};
export async function GetIpAdress() {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    return response.json().then((data) => data.ip);
  } catch (err) {
    console.error(err);
    return ".";
  }
}

export const CookieObjToJson = (queue, lotteryName) => {
  let obj = {
    name: lotteryName,
    numbers: queue.join(),
  };
  return JSON.stringify(obj);
};

export const addFavToCookies = (name, value, expire) => {
  const date = new Date();
  date.setTime(date.getTime() + expire * 24 * 60 * 60 * 1000);
  let exp = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + exp + ";path=/";
};

export const getCookie = (name) => {
  let fullName = name + "=";
  let cspl = document.cookie.split(";");
  for (let i = 0; i < cspl.length; i++) {
    let c = cspl[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(fullName) == 0) {
      return c.substring(fullName.length, c.length);
    }
  }
  return "";
};

export const checkCookie = (check) => {
  let cookieCheck = getCookie(check);
  if (cookieCheck != "") {
    return true;
  } else {
    return false;
  }
};
