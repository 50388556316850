import React, { createContext, useContext, useEffect, useState } from "react";
import Misc_Form1 from "./Misc_Form1";
import "./RegisterGrid.css";
import "./Register.css";
import Misc_Form2 from "./Misc_Form2";
import Misc_Form3 from "./Misc_Form3";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { LayoutContext } from "../../App";

export const RegisterContext = createContext(null);
const Register = () => {
  const registerRouteCall = process.env.REACT_APP_REGISTER_ROUTE;

  const history = useHistory();
  const lang = useContext(LayoutContext);
  //Hooks used in register forms
  const [prenume, setPrenume] = useState("");
  const [nume, setNume] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  //Hook for post-request-body
  const [registerBody, setRegisterBody] = useState(null);

  //Hook to show something if account creation was successfull
  const [success, setSuccess] = useState();

  //Context value of register whole page
  const registerValueProvider = {
    nume: [nume, setNume],
    prenume: [prenume, setPrenume],
    email: [email, setEmail],
    user: [user, setUser],
    password: [password, setPassword],
  };

  //Create json body on register button clicked
  const createJson = () => {
    let body = {
      userName: user,
      password: password,
      clientId: "",
      email: email,
      firstName: prenume,
      lastName: nume,
      phoneNumber: "",
    };
    setRegisterBody(body);
  };

  useEffect(() => {
    if (success) {
      setInterval(() => {
        setSuccess(false);
      }, 5000);
    } else return;
  }, [success]);

  useEffect(() => {
    console.log(registerBody);
  }, [registerBody]);

  useEffect(() => {
    if (registerBody != null) {
      try {
        const tkn = localStorage.getItem("_tkn");
        const config = {
          headers: { Authorization: `Bearer ${tkn}` },
        };
        axios.post(registerRouteCall, registerBody, config).then((response) => {
          setSuccess(true);
        });
      } catch (error) {
        setSuccess(false);
      }
    }
  }, [registerBody]);

  return (
    <RegisterContext.Provider value={registerValueProvider}>
      <div className="register_root">
        <div className="blank_space1"></div>
        <div className="first_form">
          <Misc_Form1 />
        </div>
        <div className="second_form">
          <Misc_Form2 />
        </div>
        <div className="third_form">
          <Misc_Form3 />
        </div>
        <div className="blank_space2"></div>
        <div className="reg_button">
          <div
            className="reg_button_button"
            onClick={() => {
              createJson();
              setTimeout(() => {}, 3000);
              history.goBack();
            }}
          >
            {lang.lang[0].default.Reg_8}
          </div>
          <div className="reg_status_message">
            {success && (
              <span
                style={{ color: "red", fontSize: "12px", textAlign: "center" }}
              >
                {lang.lang[0].default.Reg_11}
              </span>
            )}
          </div>
        </div>
      </div>
    </RegisterContext.Provider>
  );
};

export default Register;
