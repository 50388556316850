import React, { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../App";
import { LotoContext } from "../Pages/Loto/Loto";
import { getCurrentDaysArr } from "../Workers/Helpers";
import "./DateSelector.css";
import * as fra from "../../src/langs/fr.json";

const DateSelector = () => {
  const lang = useContext(LayoutContext);

  const [shortDaysArr, setShortDaysArr] = useState(null);

  useEffect(() => {
    lang.lang[0].default.header_2 === "Résultats"
      ? setShortDaysArr(getCurrentDaysArr("FR-fr"))
      : setShortDaysArr(getCurrentDaysArr("EN-en"));
  }, [lang]); //Get the shorten days of week. Language culture as param
  const consumer = useContext(LotoContext); //Consume all the properties provided in Loto.js context

  const toggleActiveDate = (index) => {
    //An active item is marked as 1 in the bit-array used in Loto.js <dateSelector variable>
    let aux = [0, 0, 0, 0, 0, 0, 0, 0];
    aux[index] = 1;
    let setter = consumer.dateSelector[1]; //Equivalend of setDateSelector(aux) --| in Loto.js
    setter(aux);
  };

  const formatDateSelector = (index) => {
    return (
      shortDaysArr && (
        <span style={{ fontWeight: 600 }}>
          {shortDaysArr.shortDays[index]}{" "}
          <span style={{ fontWeight: 300 }}>
            {shortDaysArr.dayNmonth[index].day +
              "." +
              shortDaysArr.dayNmonth[index].month}
          </span>
        </span>
      )
    );
  };
  const resolveClassName = (index) => {
    if (consumer.dateSelector[0][index] === 0) return "date_selector_button";
    return "date_selector_button date_selector_is_active";
  };
  const generateDay = (index, title) => {
    return (
      <div
        className={resolveClassName(index)}
        onClick={() => {
          toggleActiveDate(index);
        }}
      >
        {title}
      </div>
    );
  };

  return (
    <div className="date_selector_root">
      {generateDay(0, lang.lang[0].default.Date_1)}
      {generateDay(1, formatDateSelector(1))}
      {generateDay(2, formatDateSelector(2))}
      {generateDay(3, formatDateSelector(3))}
      {generateDay(4, formatDateSelector(4))}
      {generateDay(5, formatDateSelector(5))}
      {generateDay(6, formatDateSelector(6))}
      {generateDay(7, formatDateSelector(7))}
    </div>
  );
};

export default DateSelector;
