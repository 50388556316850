import React from "react";
import * as data from "../../../langs/fr.json";

const Misc_Content_Miniheader = () => {
  return (
    <>
      <div className="content_miniheader_label">LOTO</div>
      <div className="content_miniheader_odds">
        <div className="content_miniheader_odd">1</div>
        <div className="content_miniheader_odd">2</div>
        <div className="content_miniheader_odd">3</div>
        <div className="content_miniheader_odd">4</div>
        <div className="content_miniheader_odd">5</div>
        <div className="content_miniheader_odd">6</div>
        <div className="content_miniheader_odd">7</div>
        <div className="content_miniheader_odd">8</div>
      </div>
    </>
  );
};

export default Misc_Content_Miniheader;
