import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/fontawesome-free-solid";
import React from "react";
import Misc_HistoryRow from "./Misc_HistoryRow";

const Misc_FinishedRows = (props) => {
  return (
    <>
      {props.historyTicketResponse.length > 0 &&
        props.historyTicketResponse.map((element, key) => {
          if (element.status === 0) {
            return (
              <div key={key}>
                <Misc_HistoryRow row={element} icon={faTimesCircle} />
              </div>
            );
          } else if (element.status === 1) {
            return (
              <div key={key}>
                <Misc_HistoryRow row={element} icon={faCheckCircle} />
              </div>
            );
          }
        })}
    </>
  );
};

export default Misc_FinishedRows;
