import React, { useContext } from "react";
import { LayoutContext } from "../../../App";
import { LotoContext } from "../Loto";

const Misc_Ticket_Stake = (props) => {
  const consumer = useContext(LotoContext);
  const postRequste = consumer.postRequste[0];

  const lang = useContext(LayoutContext);

  const changeStakeSelector = (index, setStakeSelector, setStakeValue) => {
    const auxArr = [0, 0, 0];
    auxArr[index] = 1;
    setStakeSelector(auxArr);
    if (index === 0) {
      setStakeValue(10);
      return;
    }
    if (index === 1) {
      setStakeValue(100);
      return;
    }
    if (index === 2) {
      setStakeValue(500);
      return;
    }
  };

  const onStakeChanged = (value, setter) => {
    if (value >= "0" && value <= "9") {
      setter(value);
      return;
    }
    setter("");
  };

  return (
    <>
      <div className="ticket_stake_root">
        <div className="ticket_stake_comb_number">
          <label className="ticket_stake_comb_label">
            {lang.lang[0].default.Ticket_4}
          </label>
          <label className="ticket_stake_comb_value">
            {postRequste != null
              ? consumer.queue[0].length > 0
                ? postRequste.combinations
                : "0"
              : "0"}
          </label>
        </div>
        <div className="ticket_stake_stake">
          <div className="ticket_stake_stake_buttons">
            <span className="ticket_stake_your_bet">
              {lang.lang[0].default.Ticket_5}:
            </span>
            <div className="ticket_stake_buttons_selectors">
              <div
                className={
                  consumer.stakeSelector[0][0] === 0
                    ? "ticket_stake_picker"
                    : "ticket_stake_picker stake_active"
                }
                onClick={() => {
                  changeStakeSelector(
                    0,
                    consumer.stakeSelector[1],
                    consumer.stakeValue[1]
                  );
                }}
              >
                10
              </div>
              <div
                className={
                  consumer.stakeSelector[0][1] === 0
                    ? "ticket_stake_picker"
                    : "ticket_stake_picker stake_active"
                }
                onClick={() => {
                  changeStakeSelector(
                    1,
                    consumer.stakeSelector[1],
                    consumer.stakeValue[1]
                  );
                }}
              >
                100
              </div>
              <div
                className={
                  consumer.stakeSelector[0][2] === 0
                    ? "ticket_stake_picker"
                    : "ticket_stake_picker stake_active"
                }
                onClick={() => {
                  changeStakeSelector(
                    2,
                    consumer.stakeSelector[1],
                    consumer.stakeValue[1]
                  );
                }}
              >
                500
              </div>
            </div>
          </div>
          <div className="ticket_stake_stake_stakes">
            <label className="ticket_stake_stakes_label">
              {lang.lang[0].default.Ticket_6}:
            </label>
            <input
              className="ticket_stake_stake_input"
              autoComplete="off"
              value={consumer.stakeValue[0]}
              onChange={(e) => {
                onStakeChanged(e.target.value, consumer.stakeValue[1]);
              }}
            />
          </div>
        </div>
        {/* <div className="ticket_stake_tax">
          <span className="ticket_stake_tax_amount">
            CDA Tax :5.00% = {(consumer.stakeValue[0] * 0.05).toFixed(2)}
          </span>
          <span className="ticket_stake_tax_final">
            Stake after tax =
            {(consumer.stakeValue[0] - consumer.stakeValue[0] * 0.05).toFixed(
              2
            )}
          </span>
        </div> */}
      </div>
      <div className="ticket_details_root">
        <div className="ticket_details_maxpot">
          <label className="ticket_details_maxpot_label">
            {lang.lang[0].default.Ticket_7}:
          </label>
          <label className="ticket_details_maxpot_value">
            <span className="ticket_details_maxpot_value_value">
              {postRequste != null
                ? consumer.queue[0].length > 0
                  ? postRequste.maxWin
                  : "0.00"
                : "0.00"}
            </span>
            <span className="ticket_details_maxpot_currency">
              {lang.lang[0].default.Ticket_6}
            </span>
          </label>
        </div>
        <div className="ticket_details_minpot">
          <label className="ticket_details_minpot_label">
            {lang.lang[0].default.Ticket_8}:
          </label>
          <label className="ticket_details_minpot_value">
            <span className="ticket_details_minpot_value_value">
              {postRequste != null
                ? consumer.queue[0].length > 0
                  ? postRequste?.minWin
                  : "0.00"
                : "0.00"}
            </span>
            <span className="ticket_details_minpot_value_currency">
              {lang.lang[0].default.Ticket_6}
            </span>
          </label>
        </div>
      </div>
    </>
  );
};

export default Misc_Ticket_Stake;
