import React, { useContext } from "react";
import { LayoutContext } from "../App";

const Misc_Modal_Login_Buttons = (props) => {
  const lang = useContext(LayoutContext);

  return (
    <div className="modal_buttons">
      <div>{lang.lang[0].default.Login_7}</div>
      <div className="goto_link">{lang.lang[0].default.Login_8}</div>
    </div>
  );
};

export default Misc_Modal_Login_Buttons;
