import React, { useContext } from "react";
import { LayoutContext } from "../../App";
import Misc_GenericSideMenu from "./SideMenu/Misc_GenericSideMenu";
import Misc_TopIdx from "./SideMenu/Misc_TopIdx";

const SideMenu = () => {
  const consumer = useContext(LayoutContext);

  return (
    <div className="side_menu_root">
      <div className="top_idx_lotteries">
        <Misc_TopIdx whatFor="lotto" />
      </div>
      <div className="tabs_menus">
        <Misc_GenericSideMenu whatFor="lotto" lang={consumer.lang[0]} />
      </div>
    </div>
  );
};

export default SideMenu;
