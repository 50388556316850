import {
  faChartArea,
  faStar,
  faSync,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { PostRequestCalculate } from "../../../Workers/API_Manager";
import { LotoContext } from "../Loto";
import Misc_PlayTable_Combinations from "./Misc_PlayTable_Combinations";
import Misc_PlayTable_Favorites from "./Misc_PlayTable_Favorites";
import Misc_PlayTable_Header from "./Misc_PlayTable_Header";
import Misc_PlayTable_Number_Generator from "./Misc_PlayTable_Number_Generator";
import Misc_PlayTable_PlayTable from "./Misc_PlayTable_PlayTable";
import Misc_PlayTable_Specials from "./Misc_PlayTable_Specials";

const Misc_PlayTable = (props) => {
  const consumer = useContext(LotoContext);

  const [mobileClick, setMobileClick] = useState(null);
  const [shower, setShower] = useState(false);

  const handleIconClick = (index) => {
    setMobileClick(index);
  };
  const handleShow = (index) => {
    if (shower === false) setShower(!shower);
    else if (shower === true) {
      if (index === mobileClick) setShower(false);
    }
  };

  useEffect(() => {
    if (consumer.queue[0].length > 0) {
      let combsString = consumer.combinationsString[0].join();
      let toSendCombs =
        combsString.length > 0
          ? combsString
          : consumer.queue[0].length >
            consumer.lotteryDetails[0].lottery.betSystem
          ? consumer.lotteryDetails[0].lottery.betSystem
          : consumer.queue[0].length;

      PostRequestCalculate(
        consumer.lotteryDetails[0]?.lottery.id,
        consumer.stakeValue[0],
        consumer.queue[0].join(),
        toSendCombs,
        consumer.postRequste[1]
      );
    }
  }, [consumer.queue[0]]);

  const mobileSpecialsGenerator = (index) => {
    switch (index) {
      case 1:
        return <Misc_PlayTable_Number_Generator />;
      case 2:
        return <Misc_PlayTable_Combinations />;
      case 3:
        return <Misc_PlayTable_Favorites />;
      default:
        break;
    }
  };

  return (
    <div className="playtable_root">
      <div className="playtable_header">
        {consumer.lotteryDetails[0] && (
          <Misc_PlayTable_Header
            toggleplay={props.toggleplay}
            playtoggle={props.playtoggle}
            timer={props.timer}
          />
        )}
      </div>
      <div className="playtable_left_specials">
        <Misc_PlayTable_Specials />
      </div>
      <div className="playtable_right_game">
        <Misc_PlayTable_PlayTable />
      </div>
      <div className="playtable_header_mobile">
        <div
          className="playtable_header_mobile_random"
          onClick={(e) => {
            e.preventDefault();
            handleShow(1);
            handleIconClick(1);
          }}
        >
          <FontAwesomeIcon
            icon={faSync}
            className="playtable_header_mobile_icon"
          />
        </div>
        <div
          className="playtable_header_mobile_random"
          onClick={(e) => {
            e.preventDefault();
            handleShow(2);
            handleIconClick(2);
          }}
        >
          <FontAwesomeIcon
            icon={faChartArea}
            className="playtable_header_mobile_icon"
          />
        </div>
        <div
          className="playtable_header_mobile_random"
          onClick={(e) => {
            e.preventDefault();
            handleShow(3);
            handleIconClick(3);
          }}
        >
          <FontAwesomeIcon
            icon={faStar}
            className="playtable_header_mobile_icon"
          />
        </div>
      </div>
      {shower && mobileClick != null && (
        <div
          className="playtable_mobile_special_buttons_res"
          style={{ color: "white" }}
        >
          {mobileSpecialsGenerator(mobileClick)}
        </div>
      )}
    </div>
  );
};

export default Misc_PlayTable;
