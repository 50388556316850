import axios from "axios";
import { GetCookieApi } from "./API_Manager";

const authUrl = process.env.REACT_APP_AUTH_URL;
const logg_Url = process.env.REACT_APP_AUTH_LOGIN;
const client_id = process.env.REACT_APP_CLIENT_ID;
const client_s = process.env.REACT_APP_CLIENT_SECRET;
const contentType = process.env.REACT_APP_CONTENT_TYPE;

// export const InitToken = async () => {
//   const params = new URLSearchParams();
//   params.append("client_id", client_id);
//   params.append("client_secret", client_s);
//   params.append("scope", "anonymous");
//   params.append("grant_type", "client_credentials");

//   const config = {
//     headers: {
//       "Content-Type": contentType,
//     },
//   };

//   const response = await axios.post(authUrl, params, config);
//   return response.data.access_token;
// };
export const InitToken = async (setterLoader) => {
  const params = new URLSearchParams();
  params.append("client_id", client_id);
  params.append("client_secret", client_s);
  params.append("scope", "anonymous");
  params.append("grant_type", "client_credentials");

  const config = {
    headers: {
      "Content-Type": contentType,
    },
  };

  const response = await axios.post(authUrl, params, config);
  return response.data.access_token;
};

export const LoggToken = async (user, pass, lvCastSetter,lvCastNameSetter, cookieManager) => {
  const data = {
    userName: user,
    password: pass,
    clientId: "",
  };
  const config = {
    headers: {
      "Content-Type": "application/json-patch+json",
    },
  };
  const client = axios.create({ baseURL: logg_Url });
  client.interceptors.response.use((res) => {
    if (res.data.status === false) {
      const err = new Error(res.data.message);
      err.response = res;
      return err;
    }
    return res;
  });

  let response;
  await client
    .post(logg_Url, data, config)
    .then((res) => {
      response = res;
      document.cookie = "lvCast=;expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
      document.cookie =
        "lvCastName=;expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
      localStorage.setItem("_tknLog", response.data.keyValue);
    })
    .catch((error) => (response = error));
  return response;
  // if (response.keyValue) {
  //   localStorage.setItem("_tkn", response.data.keyValue);
  // }
};
