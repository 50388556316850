import React, { useContext, useEffect, useState } from "react";
import { ResultsContext } from "../../Results/Results";
import { LotoContext } from "../Loto";
import Misc_GenericSideMenu_List from "./Misc_GenericSideMenu_List";
import Misc_GenericSideMenu_Tabs from "./Misc_GenericSideMenu_Tabs";
import Misc_Generic_All_Country from "./Misc_Generic_All_Country";
import Misc_Generic_All_System from "./Misc_Generic_All_Systems";
import Misc_Generic_Country_Item from "./Misc_Generic_Country_Item";
import Misc_Generic_System_Item from "./Misc_Generic_System_Item";
import $ from "jquery";
import { getCookie } from "../../../Workers/Helpers";
import Misc_Favorites_Items from "./Misc_Favorites_Items";

const Misc_GenericSideMenu = (props) => {
  const consumer = useContext(LotoContext);
  const resultConsumer = useContext(ResultsContext);

  const [countryOpenId, SetCountryOpenId] = useState("home");
  const toggleCountryOpenId = (id) => {
    SetCountryOpenId(id);
  };
  const specificItemForLotteries = (
    key,
    name,
    countryCode,
    openId,
    currentId,
    subElements,
    hasCamera
  ) => {
    return (
      <Misc_Generic_Country_Item
        key={key}
        name={name}
        countryCode={countryCode}
        openId={openId}
        currentId={currentId}
        subElements={subElements}
        whatFor={props.whatFor}
        hasCamera={hasCamera}
      />
    );
  };

  const specificItemForSystems = (element) => {
    return (
      <Misc_Generic_System_Item element={element} whatFor={props.whatFor} />
    );
  };

  const dynamicMenuChanger = () => {
    let tabSelected =
      props.whatFor === "lotto"
        ? consumer.genericTabs[0].indexOf(1)
        : resultConsumer.genericTabs[0].indexOf(1);
    if (tabSelected === 0) {
      return (
        <Misc_GenericSideMenu_List
          allButton={
            <Misc_Generic_All_Country
              openId={countryOpenId}
              toggleOpenId={toggleCountryOpenId}
              whatFor={props.whatFor}
            />
          }
          openId={countryOpenId}
          toggleOpenId={toggleCountryOpenId}
          lotteries={
            props.whatFor === "lotto"
              ? consumer.dailyLotteries[0]
              : resultConsumer.resultsDailyLotteries[0]
          }
          specificItem={specificItemForLotteries}
          whatFor={props.whatFor}
        />
      );
    }
    if (tabSelected === 1) {
      return (
        <Misc_GenericSideMenu_List
          allButton={<Misc_Generic_All_System whatFor={props.whatFor} />}
          lotteries={
            props.whatFor === "lotto"
              ? consumer.dailyLotteriesSystem[0]
              : resultConsumer.resultsDailyLotteriesSystem[0]
          }
          specificItem={specificItemForSystems}
          whatFor={props.whatFor}
        />
      );
    }
    if (tabSelected === 2) {
      return <Misc_Favorites_Items lotteries={getFavsLotteriesList()} />;
    }
  };

  const [topIdxezCount, setTopIdxezCount] = useState(3);
  const [totalHeigth, setTotalHeigth] = useState(0);

  useEffect(() => {
    if (topIdxezCount != null) {
      setTotalHeigth(getMaxHeigth());
    }
  }, [topIdxezCount]);

  const getMaxHeigth = () => {
    let topIdxezH = topIdxezCount * 40;
    let otherSpacing = 230;
    let total = topIdxezH + otherSpacing;
    return "calc(100vh - " + total + "px)";
  };

  const getFavsLotteriesList = () => {
    let currentValues = getCookie("lvCastName");
    let x = currentValues.split("-");
    return x;
  };

  return (
    <div className="generic_sidemenu_root">
      <div className="generic_tabs">
        <Misc_GenericSideMenu_Tabs whatFor={props.whatFor} />
      </div>
      {totalHeigth && (
        <div className="generic_list" style={{ maxHeight: totalHeigth }}>
          {dynamicMenuChanger()}
        </div>
      )}
    </div>
  );
};

export default Misc_GenericSideMenu;
