import React from "react";
import Misc_PlayTable_Combinations from "./Misc_PlayTable_Combinations";
import Misc_PlayTable_Favorites from "./Misc_PlayTable_Favorites";
import Misc_PlayTable_Number_Generator from "./Misc_PlayTable_Number_Generator";

const Misc_PlayTable_Specials = (props) => {
  return (
    <div className="playtable_specials_root">
      <div className="playtable_specials_num_gen">
        <Misc_PlayTable_Number_Generator />
      </div>
      <div className="playtable_specials_combinations">
        <Misc_PlayTable_Combinations />
      </div>
      <div className="playtable_specials_favs">
        <Misc_PlayTable_Favorites />
      </div>
    </div>
  );
};

export default Misc_PlayTable_Specials;
