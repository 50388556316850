import React, { createContext, useEffect, useState } from "react";
import "./ResultsGrid.css";
import "./Results.css";
import SideMenuResults from "./SideMenuResults";
import StuffSelector from "../../Selectors/StuffSelector";
import {
  GetResultsContentByCoutry,
  GetResultsContentBySystem,
  GetResultsDailyCountries,
  GetResultsDailySystems,
  GetResultsTopIdx,
} from "../../Workers/API_Manager";
import ContentResults from "./ContentResults";
import Calendar from "./Calendar";
import SideMobile from "./Mobile/SideMobile";

export const ResultsContext = createContext(null);
const Results = () => {
  const [stuffSelector, setStuffSelector] = useState([0, 0, 1, 0, 0, 0]);
  const [genericTabs, setGenericTabs] = useState([1, 0]);
  const [topIdxLotteries, setTopIdxLotteries] = useState(null);
  const [resultsDailyLotteries, setResultsDailyLotteries] = useState(null);
  const [resultsDailyLotteriesSystem, setResultsDailyLotteriesSystem] =
    useState(null);
  const [resultsDailyContent, setResultsDailyContent] = useState(null);
  const [buildSystemFilter, setBuildSystemFilter] = useState([]);
  const [buildSystemFilterNames, setBuildSystemFilterNames] = useState([]);
  const [calendarSelector, setCalendaSelector] = useState([
    1, 0, 0, 0, 0, 0, 0, 0,
  ]);

  const [count, setCount] = useState({
    prev: 0,
    next: 50,
  });
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(null);

  const getMoreData = () => {
    if (current.length === resultsDailyContent.length) {
      setHasMore(false);
      setCount({ prev: 50, next: 100 });
      return;
    }
    setTimeout(() => {
      setCurrent(
        current.concat(
          resultsDailyContent.slice(count.prev + 1, count.next + 1)
        )
      );
    }, 500);
    setCount((prevState) => ({
      prev: prevState.prev + 50,
      next: prevState.next + 50,
    }));
  };
  const initMoreData = () => {
    resultsDailyContent != null &&
      setCurrent(resultsDailyContent.slice(count.prev, count.next));
  };
  useEffect(() => {
    if (resultsDailyContent != null && resultsDailyContent.length < 100) {
      setCount({ prev: 0, next: 50 });
      setHasMore(false);
      setCurrent(resultsDailyContent);
    } else if (
      resultsDailyContent != null &&
      resultsDailyContent.length >= 100
    ) {
      setCount({ prev: 50, next: 100 });
      setHasMore(true);
      setCurrent(resultsDailyContent.slice(0, 50));
    }
  }, [resultsDailyContent]);

  useEffect(() => {
    GetResultsTopIdx(0, setTopIdxLotteries);
    GetResultsDailyCountries(0, setResultsDailyLotteries);
    GetResultsDailySystems(0, setResultsDailyLotteriesSystem);
    GetResultsContentByCoutry(0, "", setResultsDailyContent);
  }, []);
  useEffect(() => {
    GetResultsTopIdx(calendarSelector.indexOf(1), setTopIdxLotteries);
    GetResultsDailyCountries(
      calendarSelector.indexOf(1),
      setResultsDailyLotteries
    );
    GetResultsDailySystems(
      calendarSelector.indexOf(1),
      setResultsDailyLotteriesSystem
    );
    GetResultsContentByCoutry(
      calendarSelector.indexOf(1),
      "",
      setResultsDailyContent
    );
  }, [calendarSelector]);

  const contextValuesProvider = {
    stuffSelector: [stuffSelector, setStuffSelector],
    genericTabs: [genericTabs, setGenericTabs],
    topIdxLotteries: [topIdxLotteries, setTopIdxLotteries],
    resultsDailyLotteries: [resultsDailyLotteries, setResultsDailyLotteries],
    resultsDailyLotteriesSystem: [
      resultsDailyLotteriesSystem,
      setResultsDailyLotteriesSystem,
    ],
    resultsDailyContent: [resultsDailyContent, setResultsDailyContent],
    buildSystemFilter: [buildSystemFilter, setBuildSystemFilter],
    buildSystemFilterNames: [buildSystemFilterNames, setBuildSystemFilterNames],
    calendarSelector: [calendarSelector, setCalendaSelector],

    count: [count, setCount],
    hasMore: [hasMore, setHasMore],
    current: [current, setCurrent],
    getMoreData,
    initMoreData,
  };

  useEffect(() => {
    if (buildSystemFilter.length > 0) {
      let str = buildSystemFilter.join(",");
      GetResultsContentBySystem(
        calendarSelector.indexOf(1),
        str,
        setResultsDailyContent
      );
    }
  }, [buildSystemFilter]); //called whenever a new system is selected in the SideMenu - System section

  const [toggler, setToggler] = useState(false);
  const toggleToggler = () => setToggler(!toggler);

  return (
    <ResultsContext.Provider value={contextValuesProvider}>
      <div className="results_root">
        <div className="results_side_mobile">
          <SideMobile toggler={toggler} toggleToggler={toggleToggler} />
        </div>
        <div className="results_side_menu">
          <SideMenuResults />
        </div>
        <div className="results_stuff_selector">
          <StuffSelector />
        </div>
        <div className="results_content">
          <ContentResults />
        </div>
        <div className="results_calendar_side">
          <Calendar />
        </div>
      </div>
      {toggler && (
        <div
          className="side_mobile_menu"
          onClick={(e) => {
            if (e.target === e.currentTarget) setToggler(false);
          }}
        >
          <div className="side_mobile_wrapper">
            <SideMenuResults />
          </div>
        </div>
      )}
    </ResultsContext.Provider>
  );
};

export default Results;
