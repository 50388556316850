import React, { useContext, useEffect, useState } from "react";
import {
  GetContentByLottery,
  GetCookieApi,
  GetDailyContent,
  GetResultsContentByLottery,
  PostCookieApi,
} from "../../../Workers/API_Manager";
import { fromStringToArray } from "../../../Workers/Convertors";
import {
  addFavToCookies,
  checkCookie,
  getCookie,
} from "../../../Workers/Helpers";
import { ResultsContext } from "../../Results/Results";
import { LotoContext } from "../Loto";

const Misc_Generic_Country_Subitem = (props) => {
  const consumer = useContext(LotoContext);
  const resultConsumer = useContext(ResultsContext);
  const [over, setOver] = useState(false);
  const [isInCookie, setIsInCookie] = useState(false);
  const handleIsInCookie = () => setIsInCookie(!isInCookie);

  const cookieManager = (cookieName, whatToAdd) => {
    let alreadyExists = checkCookie(cookieName);
    if (alreadyExists === false) {
      addFavToCookies(cookieName, whatToAdd);
    } else {
      let currentValues = getCookie(cookieName);
      currentValues += "-";
      currentValues += whatToAdd;
      addFavToCookies(cookieName, currentValues);
    }
  };
  const removeFromFavs = (favId, cookieName) => {
    let string = getCookie(cookieName);
    if (string.charAt(0) === "-") {
      let x = string.substring(1, string.length);
      x.replace(favId, "");
      addFavToCookies(cookieName, x);
    } else {
      let y = string.replace(favId, "");
      if (y.charAt(0) === "-") {
        y = y.substring(1, y.length);
      }
      if (y.charAt(y.length - 1) === "-") {
        y = y.substring(0, y.length - 1);
      }
      // if (y.charAt(0) != "-" && y.charAt(y.length) != "-") {
      //   let index = y.indexOf("-");
      //   y = y.replace(index, "");
      // }
      y = y.replace("--", "-");
      addFavToCookies(cookieName, y);
    }
  };

  const checkIfInCookie = (id, cookieName) => {
    let currentValues = getCookie(cookieName);
    let x = currentValues.split("-");
    if (x.indexOf(id.toString()) != -1) {
      return true;
    } else {
      return false;
    }
  };

  const ConditionalFetcher = (whatFor) => {
    if (whatFor === "lotto") {
      GetContentByLottery(
        consumer.dateSelector[0].indexOf(1),
        props.currentId,
        consumer.dailyContent[1]
      );
    } else {
      GetResultsContentByLottery(
        resultConsumer.calendarSelector[0].indexOf(1),
        props.currentId,
        resultConsumer.resultsDailyContent[1]
      );
    }
  };

  const cookieManagerLoggedOrNot = () => {
    checkIfInCookie(props.currentId, "lvCast")
      ? removeFromFavs(props.currentId, "lvCast")
      : cookieManager("lvCast", props.currentId);
    checkIfInCookie(props.element.name, "lvCastName")
      ? removeFromFavs(props.element.name, "lvCastName")
      : cookieManager("lvCastName", props.element.name);
  };

  const [getCookiez, setGetCookiez] = useState(null);

  const cookieManagerLogged = () => {
    if (sessionStorage.getItem("_isLogged")) {
      let lvCast = getCookie("lvCast");
      let lvCastName = getCookie("lvCastName");
      PostCookieApi("lvCast", lvCast);
      PostCookieApi("lvCastName", lvCastName);
    }
  };
  useEffect(() => {
    console.log(getCookiez);
  }, [getCookiez]);
  return (
    <div
      className={
        props.openId === props.currentId
          ? "generic_country_subitem subitem_active"
          : "generic_country_subitem"
      }
      onClick={(e) => {
        props.toggleOpenId(props.element.id);
        e.stopPropagation();
        ConditionalFetcher(props.whatFor);
      }}
    >
      <div className="generic_subitem_spaning">
        {props.element.name}-{fromStringToArray(props.element.system, "/")[0]}/
        {fromStringToArray(props.element.system, "/")[1]}
      </div>
      <img
        src={
          checkIfInCookie(props.currentId, "lvCast")
            ? "full_star.png"
            : over
            ? "full_star.png"
            : "empty_star.png"
        }
        width="21px"
        height="20px"
        className="generic_subitem_camera"
        onMouseOver={() => setOver(true)}
        onMouseOut={() => setOver(false)}
        onClick={(e) => {
          e.stopPropagation();
          cookieManagerLoggedOrNot();
          cookieManagerLogged();
          handleIsInCookie();
        }}
      />
    </div>
  );
};

export default Misc_Generic_Country_Subitem;
