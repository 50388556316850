import React, { useContext } from "react";
import { getCurrentDaysArrResults } from "../../Workers/Helpers";
import { ResultsContext } from "./Results";

const Calendar = (props) => {
  const consumer = useContext(ResultsContext);
  const shortDaysArr = getCurrentDaysArrResults("EN-en");

  const formatDateSelector = (index) => {
    return (
      <span style={{ fontWeight: 600 }}>
        {shortDaysArr.shortDays[index]}{" "}
        <span style={{ fontWeight: 300 }}>
          {shortDaysArr.dayNmonth[index].day +
            "." +
            shortDaysArr.dayNmonth[index].month}
        </span>
      </span>
    );
  };

  const toggleActiveDate = (index) => {
    //An active item is marked as 1 in the bit-array used in Loto.js <dateSelector variable>
    let aux = [0, 0, 0, 0, 0, 0, 0, 0];
    aux[index] = 1;
    let setter = consumer.calendarSelector[1]; //Equivalend of setDateSelector(aux) --| in Loto.js
    setter(aux);
  };

  const resolveClassName = (index) => {
    if (consumer.calendarSelector[0][index] === 0)
      return "calendar_selector_button";
    return "calendar_selector_button calendar_selector_is_active";
  };

  const generateDay = (index, title) => {
    return (
      <div
        className={resolveClassName(index)}
        onClick={() => {
          toggleActiveDate(index);
        }}
      >
        {title}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          color: "white",
          fontSize: "10px",
          marginTop: "10px",
          marginBottom: "10px",
          zIndex: "1000",
        }}
      >
        Calendar selector under development, use the selector bellow for testing
      </div>
      <div className="calendar_root">
        {generateDay(0, "Today")}
        {generateDay(1, formatDateSelector(1))}
        {generateDay(2, formatDateSelector(2))}
        {generateDay(3, formatDateSelector(3))}
        {generateDay(4, formatDateSelector(4))}
        {generateDay(5, formatDateSelector(5))}
        {generateDay(6, formatDateSelector(6))}
        {generateDay(7, formatDateSelector(7))}
      </div>
    </>
  );
};

export default Calendar;
