import React, { useContext, useEffect } from "react";
import Misc_ContentResults_Single_Row from "./Misc_ContentResults_Single_Row";
import InfiniteScroll from "react-infinite-scroll-component";
import { ResultsContext } from "../Results";
const Misc_ContentResults_List = (props) => {
  const consumer = useContext(ResultsContext);
  useEffect(() => {
    consumer.initMoreData();
  }, [consumer.resultsDailyContent[0]]);

  return (
    consumer.current[0] != null && (
      <InfiniteScroll
        dataLength={consumer.current[0].length}
        next={consumer.getMoreData}
        hasMore={consumer.hasMore[0]}
      >
        <div>
          {consumer.current[0] &&
            consumer.current[0].map((item, index) => (
              <div key={index}>
                <Misc_ContentResults_Single_Row lottery={item} />
              </div>
            ))}
        </div>
      </InfiniteScroll>
    )
  );
};

export default Misc_ContentResults_List;
