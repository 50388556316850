import { faVideo } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import {
  GetContentByLottery,
  GetDailyContent,
  GetResultsContentByLottery,
} from "../../../Workers/API_Manager";
import { textFormatter } from "../../../Workers/Convertors";
import {
  addFavToCookies,
  checkCookie,
  createFlag,
  getCookie,
} from "../../../Workers/Helpers";
import { ResultsContext } from "../../Results/Results";
import { LotoContext } from "../Loto";

const Misc_TopIdx_SingleElement = (props) => {
  const consumer = useContext(LotoContext);
  const resultConsumer = useContext(ResultsContext);

  const [over, setOver] = useState(false);
  const [isInCookie, setIsInCookie] = useState(false);

  const handleIsInCookie = () => setIsInCookie(!isInCookie);

  const ConditionalFetcher = (whatFor) => {
    if (whatFor === "lotto") {
      GetContentByLottery(
        consumer.dateSelector[0].indexOf(1),
        props.id,
        consumer.dailyContent[1]
      );
      consumer.queue[1]([]);
      return;
    } else {
      GetResultsContentByLottery(
        resultConsumer.calendarSelector[0].indexOf(1),
        props.id,
        resultConsumer.resultsDailyContent[1]
      );
    }
  };

  const cookieManager = () => {
    let alreadyExists = checkCookie("lvCast");
    if (alreadyExists === false) {
      addFavToCookies("lvCast", props.id);
    } else {
      let currentValues = getCookie("lvCast");
      currentValues += "-";
      currentValues += props.id;
      addFavToCookies("lvCast", currentValues);
    }
  };

  const removeFromFavs = (favId) => {
    let string = getCookie("lvCast");
    if (string.charAt(0) === "-") {
      let x = string.substring(1, string.length);
      x.replace(favId, "");
      addFavToCookies("lvCast", x);
    } else {
      let y = string.replace(favId, "");
      if (y.charAt(0) === "-") {
        y = y.substring(1, y.length);
      }
      if (y.charAt(y.length - 1) === "-") {
        y = y.substring(0, y.length - 1);
      }
      // if (y.charAt(0) != "-" && y.charAt(y.length) != "-") {
      //   let index = y.indexOf("-");
      //   y = y.replace(index, "");
      // }
      y = y.replace("--", "-");
      addFavToCookies("lvCast", y);
    }
  };
  const checkIfInCookie = (id) => {
    let currentValues = getCookie("lvCast");
    let x = currentValues.split("-");
    if (x.indexOf(id.toString()) != -1) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      className="top_idx_single_element"
      key={props.keyz}
      onClick={() => {
        ConditionalFetcher(props.whatFor);
      }}
    >
      <div className="top_idx_logo">
        <img
          src={createFlag(props.countryCode)}
          width="20px"
          height="20px"
          className="top_idx_logo_i"
        />
      </div>
      <div className="top_idx_span">{textFormatter(props.lotteryName, 23)}</div>
      <div className="top_idx_balls_logo">
        <div className="top_idx_guess">{props.drawBalls}</div>
        <div className="top_idx_total">{props.ballsCount}</div>
      </div>
      <div className="top_idx_camera">
        {/* {props.hasCamera && (
          <img
            src={
              checkIfInCookie(props.id) ? "camera_light2.png" : "camera_1.png"
            }
            width="25px"
            height="20px"
            className="top_idx_camera_i"
            onMouseOver={() => setOver(true)}
            onMouseOut={() => setOver(false)}
            onClick={(e) => {
              e.stopPropagation();
              checkIfInCookie(props.id)
                ? removeFromFavs(props.id)
                : cookieManager();
              handleIsInCookie();
            }}
          />
        )} */}
      </div>
    </div>
  );
};

export default Misc_TopIdx_SingleElement;
