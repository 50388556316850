import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import DateSelector from "../../Selectors/DateSelector";
import "./LotoGrid.css";
import "./Loto.css";
import SideMenu from "./SideMenu";
import HourSelector from "../../Selectors/HourSelector";
import {
  BookTicket,
  GetContentByCountry,
  GetContentBySystem,
  GetDailyContent,
  GetDailyCountries,
  GetDailyIndex,
  GetDailySystems,
  GetDailyTopIdx,
  PostRequestCalculate,
} from "../../Workers/API_Manager";
import Content from "./Content";
import Ticket from "./Ticket";
import ModalTicket from "./Ticket/ModalTicket";
import SideMobile from "./Mobile/SideMobile";
import SideTicket from "./Mobile/SideTicket";
import { LayoutContext } from "../../App";

export const LotoContext = createContext(null);
const Loto = () => {
  const [dateSelector, setDateSelector] = useState([1, 0, 0, 0, 0, 0, 0, 0]); //used to pass and store the value of the day selected in UI
  const [hourSelector, setHourSelector] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]); //used to pass and store the value of hour selected in UI
  const [genericTabs, setGenericTabs] = useState([1, 0, 0]); //used to keep track of what side-menu is displayed (country/system), by default: country

  const [dailyLotteries, setDailyLotteries] = useState(null); //used to store lotteries for side-menu
  const [dailyLotteriesSystem, setDailyLotteriesSystem] = useState(null); //used to store lotteries for side-menu->System selections
  const [topIdxLotteries, setTopIdxLotteries] = useState(null); // used to store top idx lotteries
  const [dailyContent, setDailyContent] = useState(null); //used to store content with spec filters
  const [buildSystemFilter, setBuildSystemFilter] = useState([]); //used to store wich checkBox is selected in side-menu, system section. Whenever this changes, a new api-call is initiated
  const [buildSystemFilterNames, setBuildSystemFilterNames] = useState([]); //used to store the names of checkboxes selected in side-menu. Used to deactivate the selected checkBoxes when ALL LOTTERIES checkbox is selected
  const [lotteryDetails, setLotteryDetails] = useState(null); //used to store details about a single lottery
  const [queue, setQueue] = useState([]); //used to keep track of the numbers played by the player
  const [selectedLotteryId, setSelectedLotteryId] = useState(null); //used to store the selected specific scheduled ID for reseting the queue whnever is necessary
  const [combinationsString, setCombinationsString] = useState([]); //used to store which system combinations the user is selecting
  const [stakeValue, setStakeValue] = useState(2); //used to store the betAmount
  const [postRequste, setPostRequest] = useState(null); //used to store the response whenever a new request to calculate wins is made
  const [stakeSelector, setStakeSelector] = useState([0, 0, 0]); //used to keep track of what stake is selected between the predefinded ones
  const [jsonBody, setJsonBody] = useState(null); //used to store the json that will be send when PLACE BET is pressed
  const [bookResponse, setBookResponse] = useState(null); //used to store the response from place bet action
  const [showTicketModal, setShowTicketModal] = useState(false); //used to determine if the modal for ticket will open or close
  const [selectedLotteryDate, setSelectedLotteryDate] = useState(null); //used to store the lottery Close Time
  const [errMsg, setErrMsg] = useState(null);
  const handleModalOpen = () => setShowTicketModal(true);
  const handleModalClose = () => setShowTicketModal(false);

  const [count, setCount] = useState({
    prev: 0,
    next: 50,
  });
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(null);
  const [theTkn, setTheTkn] = useState(null);

  const getMoreData = () => {
    if (current.length === dailyContent.length) {
      setHasMore(false);
      setCount({ prev: 50, next: 100 });
      return;
    }
    setTimeout(() => {
      setCurrent(
        current.concat(dailyContent.slice(count.prev + 1, count.next + 1))
      );
    }, 500);
    setCount((prevState) => ({
      prev: prevState.prev + 50,
      next: prevState.next + 50,
    }));
  };
  const initMoreData = () => {
    dailyContent != null &&
      setCurrent(dailyContent.slice(count.prev, count.next));
  };
  useEffect(() => {
    if (dailyContent != null && dailyContent.length < 100) {
      setCount({ prev: 0, next: 50 });
      setHasMore(false);
      setCurrent(dailyContent);
    } else if (dailyContent != null && dailyContent.length >= 100) {
      setCurrent(dailyContent.slice(0, 50));
      setCount({ prev: 50, next: 100 });
      setHasMore(true);
    }
  }, [dailyContent]);

  const contextValuesProvider = {
    dateSelector: [dateSelector, setDateSelector],
    hourSelector: [hourSelector, setHourSelector],
    genericTabs: [genericTabs, setGenericTabs],
    dailyLotteries: [dailyLotteries, setDailyLotteries],
    dailyLotteriesSystem: [dailyLotteriesSystem, setDailyLotteriesSystem],
    topIdxLotteries: [topIdxLotteries, setTopIdxLotteries],
    dailyContent: [dailyContent, setDailyContent],
    buildSystemFilter: [buildSystemFilter, setBuildSystemFilter],
    buildSystemFilterNames: [buildSystemFilterNames, setBuildSystemFilterNames],
    lotteryDetails: [lotteryDetails, setLotteryDetails],
    queue: [queue, setQueue],
    selectedLotteryId: [selectedLotteryId, setSelectedLotteryId],
    combinationsString: [combinationsString, setCombinationsString],
    stakeValue: [stakeValue, setStakeValue],
    postRequste: [postRequste, setPostRequest],
    stakeSelector: [stakeSelector, setStakeSelector],
    jsonBody: [jsonBody, setJsonBody],
    bookResponse: [bookResponse, setBookResponse],
    ticketModal: [handleModalOpen, handleModalClose],
    selectedLotteryDate: [selectedLotteryDate, setSelectedLotteryDate],
    errMsg: [errMsg, setErrMsg],
    theTkn: [theTkn, setTheTkn],

    count: [count, setCount],
    hasMore: [hasMore, setHasMore],
    current: [current, setCurrent],
    getMoreData,
    initMoreData,
  }; //this is the context provider value variable (store datas at top-level and acces it from childrens without passing it through all childrens)

  useEffect(() => {
    setQueue([]);
  }, [selectedLotteryId]); //whenever the selected lottery id changes, clear the queue

  const firstUpdate = useRef(true);
  // useEffect(() => {
  //   if (!localStorage.getItem("_tkn")) {
  //     setInterval(() => {
  //       let xxx = localStorage.getItem("_tkn");
  //       if (xxx != null) {
  //         setTheTkn(xxx);
  //         clearInterval();
  //         return;
  //       }
  //     }, 100);
  //   }
  // }, []);

  useEffect(() => {
    GetDailyCountries(
      localStorage.getItem("_tkn"),
      dateSelector.indexOf(1),
      setDailyLotteries
    );
    // GetDailySystems(
    //   maybeNull ?? theTkn,
    //   dateSelector.indexOf(1),
    //   setDailyLotteriesSystem
    // );
    GetDailyTopIdx(
      localStorage.getItem("_tkn"),
      dateSelector.indexOf(1),
      setTopIdxLotteries
    );
    GetContentByCountry(dateSelector.indexOf(1), "", setDailyContent);
  }, []); //called when Loto.js did mount

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    GetDailyCountries(
      localStorage.getItem("_tkn"),
      dateSelector.indexOf(1),
      setDailyLotteries
    );
    GetDailySystems(
      localStorage.getItem("_tkn"),
      dateSelector.indexOf(1),
      setDailyLotteriesSystem
    );
    GetContentByCountry(dateSelector.indexOf(1), "", setDailyContent);
  }, [dateSelector]); //called whenever the selected date is changed

  useEffect(() => {
    if (buildSystemFilter.length > 0) {
      let str = buildSystemFilter.join(",");
      GetContentBySystem(dateSelector.indexOf(1), str, setDailyContent);
    }
  }, [buildSystemFilter]); //called whenever a new system is selected in the SideMenu - System section

  useEffect(() => {
    if (stakeValue != null && stakeValue > 0 && lotteryDetails != null) {
      if (stakeValue > 9999) {
        setStakeValue(9999);
      }
      PostRequestCalculate(
        lotteryDetails?.lottery.id,
        stakeValue,
        queue.join(),
        combinationsString.join(),
        setPostRequest
      );
    }
  }, [stakeValue]);

  useEffect(() => {
    if (combinationsString.length > queue.length) {
      setCombinationsString([1]);
    }
    if (combinationsString.indexOf(queue.length) != -1) {
      setCombinationsString([1]);
    }
    if (combinationsString.length === 1) {
      setCombinationsString([1]);
    }
  }, [queue.length]);

  useEffect(() => {
    if (jsonBody != null) {
      BookTicket(jsonBody, setBookResponse);
    }
  }, [jsonBody]);

  const [toggler, setToggler] = useState(false);
  const toggleToggler = () => setToggler(!toggler);
  const [togglerDate, setTogglerDate] = useState(false);
  const toggleTogglerDate = () => setTogglerDate(!togglerDate);
  const [togglerTicket, setTogglerTicket] = useState(false);
  const toggleTogglerTicket = () => setTogglerTicket(!togglerTicket);
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1280) {
        setToggler(false);
        setTogglerDate(false);
      }
    }
    window.addEventListener("resize", handleResize);
  }, []); //Used to hide the sideMenu when the width of screen is greater than 1280

  const emConsumer = useContext(LayoutContext);
  useEffect(() => {
    console.log(emConsumer.launchAuthResponse[0]);
  }, [emConsumer.launchAuthResponse[0]]);

  useEffect(() => {
    if (jsonBody != null) {
      document.querySelector(
        ".ticket_buttons_place_bet_button"
      ).style.pointerEvents = "none";
      document.querySelector(
        ".ticket_buttons_place_bet_button"
      ).style.backgroundColor = "lightgray";
      BookTicket(jsonBody, setBookResponse);
    }
  }, [jsonBody]);

  useEffect(() => {
    if (bookResponse != null) {
      document.querySelector(
        ".ticket_buttons_place_bet_button"
      ).style.pointerEvents = "auto";
      document.querySelector(
        ".ticket_buttons_place_bet_button"
      ).style.backgroundColor = "#f47f32";
      if (bookResponse.bookStatus === 9) {
        setErrMsg("Insufficient Funds");
        setShowTicketModal(false);
      } else {
        setErrMsg(null);
        setShowTicketModal(true);
      }
    }
  }, [bookResponse]);

  useEffect(() => {
    if (errMsg === "Insufficient funds" || errMsg === "Fonds insuffisants") {
      setBookResponse(null);
      setTimeout(() => {
        setErrMsg(null);
      }, 3000);
    }
  }, [errMsg]);

  return (
    <LotoContext.Provider value={contextValuesProvider}>
      <div className="loto_root">
        <div className="side_menu_mobile">
          <SideMobile
            toggler={toggler}
            toggleToggler={toggleToggler}
            toggleTogglerDate={toggleTogglerDate}
          />
        </div>
        <div className="side_menu">
          <SideMenu />
        </div>
        <div className="date_selector">
          <DateSelector />
        </div>
        {/* <div className="hour_selector">
          <HourSelector />
        </div> */}
        <div className="content">
          <Content />
        </div>
        <div className="ticket">
          <Ticket verif={true} />
        </div>
        <div className="ticket_mobile">
          <SideTicket
            toggleTogglerTicket={toggleTogglerTicket}
            queue={queue}
            stakeValue={stakeValue}
            postRequste={postRequste}
          />
        </div>
      </div>
      {bookResponse != null && (
        <ModalTicket
          open={showTicketModal}
          onclose={handleModalClose}
          queue={queue}
        />
      )}
      {toggler && (
        <div
          className="side_mobile_menu"
          onClick={(e) => {
            if (e.target === e.currentTarget) setToggler(false);
          }}
        >
          <div className="side_mobile_wrapper">
            <SideMenu />
          </div>
        </div>
      )}
      {togglerDate && (
        <div
          className="side_mobile_dateselector"
          onClick={(e) => {
            if (e.target === e.currentTarget) setTogglerDate(false);
          }}
        >
          <div className="side_mobile_date_wrapper">
            <DateSelector />
          </div>
        </div>
      )}
      {togglerTicket && (
        <div
          className="ticket_mobile_menu"
          onClick={(e) => {
            if (e.target === e.currentTarget) setTogglerTicket(false);
          }}
        >
          <div className="ticket_mobile_wrap">
            <Ticket verif={false} setTogglerTicket={setTogglerTicket} />
          </div>
        </div>
      )}
    </LotoContext.Provider>
  );
};

export default Loto;
