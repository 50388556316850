import React, { useContext } from "react";
import { LotoContext } from "../Loto";
import $ from "jquery";
import { ResultsContext } from "../../Results/Results";

const Misc_Generic_System_Item = (props) => {
  const consumer = useContext(LotoContext);
  const resultConsumer = useContext(ResultsContext);
  const builderConst =
    props.whatFor === "lotto"
      ? consumer.buildSystemFilter[0]
      : resultConsumer.buildSystemFilter[0];
  const builderSetter =
    props.whatFor === "lotto"
      ? consumer.buildSystemFilter[1]
      : resultConsumer.buildSystemFilter[1];
  const allCheckedSetter =
    props.whatFor === "lotto"
      ? consumer.buildSystemFilterNames[1]
      : resultConsumer.buildSystemFilterNames[1];

  const addSystemToString = (sysValue) => {
    let array = [...builderConst];
    array.push(sysValue);
    builderSetter(array);
    let secondarr =
      props.whatFor === "lotto"
        ? [...consumer.buildSystemFilterNames[0]]
        : [...resultConsumer.buildSystemFilterNames[0]];
    secondarr.push(sysValue);
    allCheckedSetter(secondarr);
    $("#allLot").prop("checked", false);
  };

  const removeSystemToString = (sysValue) => {
    let array = [...builderConst];
    const index = array.indexOf(sysValue);
    if (index > -1) {
      array.splice(index, 1);
    }
    builderSetter(array);
  };

  return (
    <div className="generic_system_item_root">
      <input
        id={(props.whatFor === "lotto" ? "loto" : "results") + props.element.id}
        type="checkbox"
        className=""
        defaultChecked={false}
        onChange={(e) => {
          e.target.checked
            ? addSystemToString(props.element.id)
            : removeSystemToString(props.element.id);
        }}
      />
      <label className="generic_system_item_label">
        <span className="generic_system_item_span">{props.element.name}</span>
      </label>
    </div>
  );
};

export default Misc_Generic_System_Item;
