import { faStar } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { LayoutContext } from "../../../App";
import {
  GetContentByCountry,
  GetContentBySystem,
  GetCookieApi,
  GetDailyContent,
  GetDailySystems,
  GetResultsContentByCoutry,
  GetResultsContentBySystem,
} from "../../../Workers/API_Manager";
import { ResultsContext } from "../../Results/Results";
import { LotoContext } from "../Loto";

const Misc_GenericSideMenu_Tabs = (props) => {
  const consumer = useContext(LotoContext);
  const resultConsumer = useContext(ResultsContext);
  const toggleActiveTab = (index) => {
    let aux = [0, 0, 0];
    aux[index] = 1;
    let setter =
      props.whatFor === "lotto"
        ? consumer.genericTabs[1]
        : resultConsumer.genericTabs[1];
    setter(aux);
  };

  const lang = useContext(LayoutContext);

  const resolveClassName = (index) => {
    if (props.whatFor === "lotto") {
      if (consumer.genericTabs[0][index] === 0) return "generic_side_tab";
      return "generic_side_tab generic_side_tab_active";
    } else {
      if (resultConsumer.genericTabs[0][index] === 0) return "generic_side_tab";
      return "generic_side_tab generic_side_tab_active";
    }
  };

  const ttt = () => {
    GetDailySystems(
      localStorage.getItem("_tkn"),
      consumer.dateSelector[0].indexOf(1),
      consumer.dailyLotteriesSystem[1]
    );
    GetContentBySystem(
      consumer.dateSelector[0].indexOf(1),
      "",
      consumer.dailyContent[1]
    );
  };

  const [lvCast, setLvCast] = useState(null);
  const [lvCastName, setLvCastName] = useState(null);

  const ConditionalFetcher = (index, whatFor) => {
    if (whatFor === "lotto") {
      if (index === 0) {
        GetContentByCountry(
          consumer.dateSelector[0].indexOf(1),
          "",
          consumer.dailyContent[1]
        );
      } else if (index === 1) {
        ttt();
      } else if (index === 2) {
        if (sessionStorage.getItem("_isLogged")) {
          GetCookieApi("lvCast", setLvCast);
          GetCookieApi("lvCastName", setLvCastName);
          const date = new Date();
          date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
          let exp = "expires=" + date.toUTCString();
          lvCast &&
            (document.cookie = "lvCast" + "=" + lvCast + ";" + exp + ";path=/");
          lvCastName &&
            (document.cookie =
              "lvCastName" + "=" + lvCastName + ";" + exp + ";path=/");
        }
      }
    } else {
      index === 0
        ? GetResultsContentByCoutry(
            resultConsumer.calendarSelector[0].indexOf(1),
            "",
            resultConsumer.resultsDailyContent[1]
          )
        : GetResultsContentBySystem(
            resultConsumer.calendarSelector[0].indexOf(1),
            "",
            resultConsumer.resultsDailyContent[1]
          );
    }
  };

  const generateTab = (index, toDisplay) => {
    return (
      <div
        className={resolveClassName(index)}
        onClick={() => {
          toggleActiveTab(index);
          ConditionalFetcher(index, props.whatFor);
        }}
      >
        {toDisplay}
      </div>
    );
  };

  return (
    <div className="generic_side_tabs">
      {generateTab(0, lang.lang[0].default.Menu_1)}
      {generateTab(1, lang.lang[0].default.Menu_2)}
      {generateTab(
        2,
        <FontAwesomeIcon
          icon={faStar}
          style={{ height: "20px", width: "20px" }}
        />
      )}
    </div>
  );
};

export default Misc_GenericSideMenu_Tabs;
