import React, { useContext } from "react";
import { LayoutContext } from "../../../App";
import { fromStringToArray } from "../../../Workers/Convertors";
import { LotoContext } from "../Loto";

const Misc_PlayTable_Combinations = (props) => {
  const consumer = useContext(LotoContext);
  const lang = useContext(LayoutContext);
  const StatStringManager = (string) => {
    let firstSplit = fromStringToArray(string, ":");
    let secondSplitter = fromStringToArray(firstSplit[1], ",");
    consumer.queue[1]([]);
    let auxArr = [];
    secondSplitter.forEach((element, key) => {
      auxArr[key] = parseInt(element);
    });
    consumer.queue[1](auxArr);
  };

  const changeQueueWhenStatChecked = (e) => {
    switch (e.target.id) {
      case "item_atidx1":
        StatStringManager(consumer.lotteryDetails[0]?.lottery?.SqStats[0]);
        break;
      case "item_atidx2":
        StatStringManager(consumer.lotteryDetails[0]?.lottery?.SqStats[1]);
        break;
      case "item_atidx3":
        StatStringManager(consumer.lotteryDetails[0]?.lottery?.SqStats[2]);
        break;
      case "item_atidx4":
        StatStringManager(consumer.lotteryDetails[0]?.lottery?.SqStats[3]);
        break;
      case "item_atidx5":
        StatStringManager(consumer.lotteryDetails[0]?.lottery?.SqStats[4]);
        break;
      case "item_atidx6":
        StatStringManager(consumer.lotteryDetails[0]?.lottery?.SqStats[5]);
        break;
      default:
        break;
    }
  };

  return (
    <div className="playtable_combinations_root">
      <div className="playtable_com_header">
        {lang.lang[0].default.Palette_6}
      </div>
      <div className="playtable_com_content">
        <div className="playtable_comb_radiobtn">
          <input
            id="item_atidx1"
            type="radio"
            name="combinations"
            onChange={(e) => {
              changeQueueWhenStatChecked(e);
            }}
          />
          <label htmlFor="item_atidx1">{lang.lang[0].default.Palette_7}</label>
        </div>
        <div className="playtable_comb_radiobtn">
          <input
            id="item_atidx2"
            type="radio"
            name="combinations"
            onChange={(e) => {
              changeQueueWhenStatChecked(e);
            }}
          />
          <label htmlFor="item_atidx2">{lang.lang[0].default.Palette_8}</label>
        </div>
        <div className="playtable_comb_radiobtn">
          <input
            id="item_atidx3"
            type="radio"
            name="combinations"
            onChange={(e) => {
              changeQueueWhenStatChecked(e);
            }}
          />
          <label htmlFor="item_atidx3">{lang.lang[0].default.Palette_9}</label>
        </div>
        <div className="playtable_comb_radiobtn">
          <input
            id="item_atidx4"
            type="radio"
            name="combinations"
            onChange={(e) => {
              changeQueueWhenStatChecked(e);
            }}
          />
          <label htmlFor="item_atidx4">{lang.lang[0].default.Palette_10}</label>
        </div>
        <div className="playtable_comb_radiobtn">
          <input
            id="item_atidx5"
            type="radio"
            name="combinations"
            onChange={(e) => {
              changeQueueWhenStatChecked(e);
            }}
          />
          <label htmlFor="item_atidx5">{lang.lang[0].default.Palette_11}</label>
        </div>
        <div className="playtable_comb_radiobtn">
          <input
            id="item_atidx6"
            type="radio"
            name="combinations"
            onChange={(e) => {
              changeQueueWhenStatChecked(e);
            }}
          />
          <label htmlFor="item_atidx6">{lang.lang[0].default.Palette_12}</label>
        </div>
      </div>
    </div>
  );
};

export default Misc_PlayTable_Combinations;
