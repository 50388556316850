import { data } from "jquery";
import React, { useContext, useEffect } from "react";
import { LayoutContext } from "../../../App";
import { GetScheduleContent } from "../../../Workers/API_Manager";
import { fromStringToArray } from "../../../Workers/Convertors";
import { createJsonBody } from "../../../Workers/Helpers";
import { LotoContext } from "../Loto";

const Misc_Ticket_Buttons = (props) => {
  const consumer = useContext(LotoContext);
  const lang = useContext(LayoutContext);
  const style = {};

  const checkLotteryDate = (date) => {
    let dt = new Date();
    let lotoDate = new Date(date - 10000);
    if (lotoDate < dt) return false;
    return true;
  };

  // const checkSIIII = () => {
  //   if (!checkLotteryDate(consumer.selectedLotteryDate[0])) {
  //     consumer.errMsg[1]("Bets on this event had closed!");
  //     return false;
  //   }
  //   return true;
  // };

  // useEffect(() => {
  //   setInterval(() => {
  //     if (checkLotteryDate(consumer.selectedLotteryDate[0]))
  //       consumer.queue[1]([]);
  //   }, 100);
  // }, []);

  useEffect(() => {
    console.log(consumer.selectedLotteryDate[0]);
    console.log(
      new Date(consumer.selectedLotteryDate[0]) - new Date(Date.now())
    );
  }, [consumer.selectedLotteryDate[0]]);

  const emConsumer = useContext(LayoutContext);

  return (
    <>
      <div className="ticket_buttons_place_bet">
        <button
          className="ticket_buttons_place_bet_button"
          onClick={() => {
            if (consumer.queue[0].length > 0) {
              if (
                new Date(consumer.selectedLotteryDate[0]) -
                  new Date(Date.now()) >
                36000
              ) {
                createJsonBody(
                  consumer.queue[0],
                  consumer.lotteryDetails[0]?.lottery.id,
                  consumer.stakeValue[0],
                  consumer.selectedLotteryId[0],
                  consumer.combinationsString[0],
                  consumer.lotteryDetails[0]?.lottery.outcomes.length,
                  consumer.jsonBody[1],
                  emConsumer.EMtoken[0],
                  emConsumer.EMusername[0],
                  emConsumer.EMuserId[0],
                  emConsumer.EMccy[0]
                );
                props.verif === false && props.setTogglerTicket(false);
                props.setModalOpen(true);
              } else {
                let mm = (
                  <div className="err_msg_root_stuff">
                    <div className="err_msg_msg">
                      Bets on this event are closed. Bet on the upcoming
                      lottery?
                    </div>
                    <div className="err_msg_btns">
                      <span
                        onClick={() => {
                          GetScheduleContent(
                            parseInt(consumer.selectedLotteryId[0]) + 1,
                            consumer.lotteryDetails[1],
                            consumer.selectedLotteryDate[1]
                          );
                          consumer.errMsg[1](null);
                        }}
                      >
                        Yes
                      </span>
                      <span
                        onClick={() => {
                          consumer.errMsg[1](null);
                        }}
                      >
                        No
                      </span>
                    </div>
                  </div>
                );
                consumer.errMsg[1](mm);
              }
            } else alert("Adaugati cel putin un pariu pe bilet");
          }}
        >
          {lang.lang[0].default.Ticket_9}
        </button>
      </div>
    </>
  );
};

export default Misc_Ticket_Buttons;
