import React, { useState } from "react";

const Misc_FormFields = (props) => {
  const [valueVerify, setValueVerify] = useState(false);

  return (
    <div className="form_field_root">
      <div className="form_field_input">
        <label className="input__label">{props.inputLabel}</label>
        <div className="input__box">
          <input
            className="input__field"
            onChange={(e) => {
              props.handleChange(props.fieldSetter, e.target.value);
              props.inputLabel != "Email"
                ? props.fieldVerifyer(
                    props.fieldValue,
                    props.fieldConstrain,
                    setValueVerify
                  )
                : props.fieldVerifyer(props.fieldValue, setValueVerify);
            }}
            type={props.type}
            disabled={props.disabled}
          />
        </div>
        {valueVerify && <div className="error_field">{props.errorMessage}</div>}
      </div>
    </div>
  );
};

export default Misc_FormFields;
