import React, { useContext, useEffect, useState } from "react";
import { PostRequestCalculate } from "../../Workers/API_Manager";
import { fromStringToArray } from "../../Workers/Convertors";
import { LotoContext } from "./Loto";
import Misc_Ticket_Buttons from "./Ticket/Misc_Ticket_Buttons";
import Misc_Ticket_Combinations from "./Ticket/Misc_Ticket_Combinations";
import Misc_Ticket_Header from "./Ticket/Misc_Ticket_Header";
import Misc_Ticket_Numbers from "./Ticket/Misc_Ticket_Numbers";
import Misc_Ticket_Stake from "./Ticket/Misc_Ticket_Stake";
import Misc_Ticket_Verification from "./Ticket/Misc_Ticket_Verification";

const Ticket = (props) => {
  const consumer = useContext(LotoContext);

  useEffect(() => {
    if (consumer.queue[0].length > 0) {
      PostRequestCalculate(
        consumer.lotteryDetails[0]?.lottery.id,
        consumer.stakeValue[0],
        consumer.queue[0].join(),
        consumer.combinationsString[0].join(),
        consumer.postRequste[1]
      );
    }
  }, [consumer.combinationsString[0]]);

  let ballsCount = consumer.lotteryDetails[0]
    ? fromStringToArray(consumer.lotteryDetails[0]?.lottery?.system, "/")[1]
    : null;

  return (
    <div className="ticket_root">
      {props.verif && (
        <div className="ticket_verify_ticket">
          <Misc_Ticket_Verification />
        </div>
      )}
      <div className="ticket_header">
        <Misc_Ticket_Header
          queue={consumer.queue[0]}
          setQueue={consumer.queue[1]}
          lotteryDetails={consumer.lotteryDetails[0]}
          verif={props.verif}
          setTogglerTicket={props.setTogglerTicket}
        />
      </div>
      <div className="ticket_nums">
        <Misc_Ticket_Numbers
          lotteryDetails={consumer.lotteryDetails[0]}
          queue={consumer.queue[0]}
          setQueue={consumer.queue[1]}
        />
      </div>
      <div className="ticket_combinations">
        <Misc_Ticket_Combinations
          queue={consumer.queue[0]}
          ballsCount={consumer.lotteryDetails[0]?.lottery?.outcomes.length}
          combinationsString={consumer.combinationsString[0]}
          setter={consumer.combinationsString[1]}
        />
      </div>
      <div className="ticket_stake">
        <Misc_Ticket_Stake />
      </div>
      <div className="ticket_button">
        <Misc_Ticket_Buttons
          setModalOpen={consumer.ticketModal[0]}
          setTogglerTicket={props.setTogglerTicket}
          verif={props.verif}
        />
      </div>
    </div>
  );
};

export default Ticket;
