import React, { useState } from "react";
import Misc_FormFields from "./Misc_FormFields";

const Misc_Form2 = () => {
  const [cnp, setCnp] = useState("");
  const [telefon, setTelefon] = useState("");
  const [adresa, setAdresa] = useState("");
  const [judet, setJudet] = useState("");
  const [localitate, setLocalitate] = useState("");

  const handleChange = (setter, newValue) => {
    setter(newValue);
  };
  const fieldVerifyer = (toVerify, condition, setter) => {
    if (toVerify.length < condition) {
      return setter(true);
    }
    return setter(false);
  };

  return (
    <>
      <div className="form2_title">
        Detaliile reședinței în România, conform reglementărilor
      </div>
      <div className="form2_basics">
        <Misc_FormFields
          inputLabel="CNP ~ disabled"
          handleChange={handleChange}
          fieldValue={cnp}
          fieldConstrain={12}
          fieldSetter={setCnp}
          errorMessage="Numarul de caractere este fix 13"
          fieldVerifyer={fieldVerifyer}
          disabled={true}
        />
        <br />
        <Misc_FormFields
          inputLabel="Numar de telefon ~ disabled"
          handleChange={handleChange}
          fieldValue={telefon}
          fieldConstrain={9}
          fieldSetter={setTelefon}
          errorMessage="Numarul de caractere este fix 10"
          fieldVerifyer={fieldVerifyer}
          disabled={true}
        />
        <br />
        <Misc_FormFields
          inputLabel="Adresa ~ disabled"
          handleChange={handleChange}
          fieldValue={adresa}
          fieldConstrain={3}
          fieldSetter={setAdresa}
          errorMessage="Numarul minim de caractere este fix 4"
          fieldVerifyer={fieldVerifyer}
          disabled={true}
        />
        <br />
        <Misc_FormFields
          inputLabel="Alege un judet ~ disabled"
          handleChange={handleChange}
          fieldValue={judet}
          fieldConstrain={1}
          fieldSetter={setJudet}
          errorMessage="Numarul minim de caractere este fix 2"
          fieldVerifyer={fieldVerifyer}
          disabled={true}
        />
        <br />

        <Misc_FormFields
          inputLabel="Alege localitatea ~ disabled"
          handleChange={handleChange}
          fieldValue={localitate}
          fieldConstrain={1}
          fieldSetter={setLocalitate}
          errorMessage="Numarul minim de caractere este fix 2"
          fieldVerifyer={fieldVerifyer}
          disabled={true}
        />
        <br />
        <br />
        <input type="checkbox" disabled id="codbonus" />
        <label for="codbonus" className="label_bonus_code">COD BONUS</label>
      </div>
    </>
  );
};

export default Misc_Form2;
