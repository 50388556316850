import React from "react";

const Misc_ContentResults_Miniheader = () => {
  return (
    <>
      <div className="content_results_minih_date">DATE</div>
      <div className="content_results_minih_label">LOTO</div>
      <div className="content_results_minih_nums">WINING NUMBERS</div>
    </>
  );
};

export default Misc_ContentResults_Miniheader;
