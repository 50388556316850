import React, { useContext} from "react";
import { LotoContext } from "../Loto";
import InfiniteScroll from "react-infinite-scroll-component";
import Misc_Content_Single_Row from "./Misc_Content_Single_Row";

const Misc_ContentList = (props) => {
  const consumer = useContext(LotoContext);

  return (
    <>
      {consumer.current[0] != null && (
        <InfiniteScroll
          dataLength={consumer.current[0].length}
          next={consumer.getMoreData}
          hasMore={consumer.hasMore[0]}
        >
          <div>
            {consumer.current[0] &&
              consumer.current[0].map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    props.toggleOpenId(item.id);
                  }}
                >
                  <Misc_Content_Single_Row
                    lottery={item}
                    openId={props.openId}
                    currentId={item.id}
                  />
                </div>
              ))}
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

export default Misc_ContentList;
