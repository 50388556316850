import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { LayoutContext } from "../App";

const Misc_Modal_Login_Header = (props) => {
  const lang = useContext(LayoutContext);

  return (
    <div className="modal_header">
      <div className="modal_title">{lang.lang[0].default.Login_1}</div>
      <FontAwesomeIcon
        icon={faTimes}
        onClick={props.onclose}
        className="modal_header_icon"
      />
    </div>
  );
};

export default Misc_Modal_Login_Header;
