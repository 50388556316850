import React, { useEffect, useState } from "react";
import { DateToHhMm } from "../Workers/Convertors";

const Misc_Time = () => {
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    setInterval(() => {
      setTime(new Date());
    }, 1000);
  }, []);

  return <span>{DateToHhMm(time)}</span>;
};

export default Misc_Time;
