import React, { useContext } from "react";
import {
  GetContentBySystem,
  GetDailyContent,
  GetResultsContentBySystem,
} from "../../../Workers/API_Manager";
import { LotoContext } from "../Loto";
import $ from "jquery";
import { ResultsContext } from "../../Results/Results";
import { LayoutContext } from "../../../App";

const Misc_Generic_All_System = (props) => {
  const consumer = useContext(LotoContext);
  const resultsConsumer = useContext(ResultsContext);

  const lang = useContext(LayoutContext);

  const handleCheckChanged = () => {
    if (props.whatFor === "lotto") {
      GetContentBySystem(
        consumer.dateSelector[0].indexOf(1),
        "",
        consumer.dailyContent[1]
      );
      consumer.buildSystemFilter[1]([]);
      disableAllCheckboxes();
    } else {
      GetResultsContentBySystem(
        resultsConsumer.calendarSelector[0].indexOf(1),
        "",
        resultsConsumer.resultsDailyContent[1]
      );
      resultsConsumer.buildSystemFilter[1]([]);
      disableAllCheckboxes();
    }
  };
  const disableAllCheckboxes = () => {
    if (props.whatFor === "lotto") {
      consumer.buildSystemFilterNames[0].forEach((element) => {
        $(`#loto${element}`).prop("checked", false);
      });
    } else {
      resultsConsumer.buildSystemFilterNames[0].forEach((element) => {
        $(`#results${element}`).prop("checked", false);
      });
    }
  };

  return (
    <>
      <input
        id="allLot"
        type="checkbox"
        className="generic_system_item"
        defaultChecked={false}
        onChange={(e) => {
          e.target.checked && handleCheckChanged();
        }}
      />
      <label className="generic_system_item_label">
        <span className="generic_system_item_span">
          {lang.lang[0].default.Menu_4}
        </span>
      </label>
    </>
  );
};

export default Misc_Generic_All_System;
