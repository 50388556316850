import React, { useContext, useEffect } from "react";
import { fromStringToArray } from "../../../Workers/Convertors";
import { addOrRemoveNumber } from "../../../Workers/Helpers";
import { LotoContext } from "../Loto";

const Misc_PlayTable_PlayTable = (props) => {
  const consumer = useContext(LotoContext);
  const lottery = consumer.lotteryDetails[0];

  const ganarateAllValues = (maxNumbers) => {
    return Array(maxNumbers)
      .fill()
      .map((element, key) => {
        return <div key={key}>{generateValue(key)}</div>;
      });
  };

  useEffect(() => {
    console.log(consumer.lotteryDetails[0]?.lottery?.id);
  }, []);

  const generateValue = (index) => {
    return (
      lottery && (
        <div className="playtable_pt_element">
          {consumer.lotteryDetails[0]?.lottery?.id != 92 && (
            <div className="playtable_pt_statistics">
              <div className="playtable_stats_hot">
                {lottery?.lottery?.Stats[index].A}
              </div>
              <div className="playtable_stats_cold">
                {lottery?.lottery?.Stats[index].M}
              </div>
            </div>
          )}
          <div
            className={
              consumer.queue[0].indexOf(index + 1) != -1
                ? "playtable_pt_value playtable_value_active"
                : "playtable_pt_value"
            }
            onClick={(e) => {
              addOrRemoveNumber(
                consumer.queue[0],
                index + 1,
                consumer.queue[1],
                e,
                lottery?.lottery?.maxBetNumbers
              );
            }}
          >
            <label>{index + 1 != 0 ? index + 1 : null}</label>
          </div>
        </div>
      )
    );
  };

  return (
    <div className="playtable_playtable_root">
      {lottery &&
        ganarateAllValues(
          parseInt(
            fromStringToArray(lottery?.lottery?.system.toString(), "/")[1]
          )
        )}
    </div>
  );
};

export default Misc_PlayTable_PlayTable;

///CA SA INCHZI PLAYTABLE CARE E DESCHIS... CAND SE DA CLICK PE EL FA UN SETTER LA OPENid CU CE HALESTI TU
