import React, { useContext, useEffect, useState } from "react";
import {
  GetContentByCountry,
  GetDailyContent,
  GetDailyIndexCountry,
  GetResultsContentByCoutry,
  GetResultsDailyIndexCountry,
} from "../../../Workers/API_Manager";
import { ResultsContext } from "../../Results/Results";
import { LotoContext } from "../Loto";

const Misc_GenericSideMenu_List = (props) => {
  const consumer = useContext(LotoContext);
  const resultConsumer = useContext(ResultsContext);
  const dateSelector =
    props.whatFor === "lotto" ? consumer.dateSelector[0].indexOf(1) : 0;
  const [subItems, setSubItems] = useState(null);

  const getTabIndex = () => {
    if (props.whatFor === "lotto") {
      if (consumer.genericTabs[0].indexOf(1) === 0) return 0;
      return 1;
    } else {
      if (resultConsumer.genericTabs[0].indexOf(1) === 0) return 0;
      return 1;
    }
  };
  useEffect(() => {
    if (
      (document.location.pathname === "/" ||
        document.location.pathname === "/loto") &&
      consumer.genericTabs[0].indexOf(1) === 0
    )
      props.toggleOpenId("home");
  }, [dateSelector]);

  const onClickDone = (toggleOpenId, id, imgkey) => {
    if (props.whatFor === "lotto") {
      if (getTabIndex() === 0) {
        toggleOpenId(id);
        GetDailyIndexCountry(imgkey, dateSelector, setSubItems);
        GetContentByCountry(
          dateSelector,
          id,
          consumer.dailyContent[1]
        );
      }
    } else {
      if (getTabIndex() === 0) {
        toggleOpenId(id);
        GetResultsContentByCoutry(
          resultConsumer.calendarSelector[0].indexOf(1),
          id,
          resultConsumer.resultsDailyContent[1]
        );
        GetResultsDailyIndexCountry(
          resultConsumer.calendarSelector[0].indexOf(1),
          imgkey,
          setSubItems
        );
      }
    }
  };
  return (
    <div
      className={
        getTabIndex() === 0 ? "generic_list_root" : "generic_list_root_sys"
      }
    >
      <div
        className={
          getTabIndex() === 0
            ? "generic_list_all_button"
            : "generic_list_all_button_sys"
        }
      >
        {props.allButton}
      </div>
      <div
        className={
          getTabIndex() === 0
            ? "generic_list_rest_items"
            : "generic_list_rest_items_sys"
        }
      >
        {props.lotteries &&
          props.lotteries.map((element, key) => {
            if (key > 0)
              return (
                <div
                  onClick={() =>
                    onClickDone(props.toggleOpenId, element.id, element.id)
                  }
                  key={key}
                  className={getTabIndex() === 1 ? "system_flexin_half" : null}
                >
                  {getTabIndex() === 0
                    ? props.specificItem(
                        key,
                        element.name,
                        element.id,
                        props.openId,
                        element.id,
                        subItems,
                        element.liveCast
                      )
                    : props.specificItem(element)}
                </div>
              );
          })}
      </div>
    </div>
  );
};

export default Misc_GenericSideMenu_List;
