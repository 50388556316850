import React from "react";
import { fromStringToArray } from "../../../Workers/Convertors";

const Misc_Ticket_Combinations = (props) => {
  const addOrRemoveCheckbox = (index, setter) => {
    let idx = -1;
    let auxArr = [...props.combinationsString];
    if (props.combinationsString.indexOf(index) > -1) {
      idx = props.combinationsString.indexOf(index);
      auxArr.splice(idx, 1);
      setter(auxArr);
    } else {
      auxArr.push(index);
      setter(auxArr);
    }
  };
  const generateCheckBoxes = (howMany) => {
    let defaultCheckElements = [];
    for (let i = 0; i < howMany; i++) {
      defaultCheckElements.push(
        <div className="ticket_combinations_single_elem" key={i}>
          <input
            type="checkbox"
            id={i}
            name={"checkbox" + (i + 1)}
            className="ticket_combinations_inpus"
            onClick={() => {
              addOrRemoveCheckbox(i + 1, props.setter);
            }}
            defaultChecked={
              props.combinationsString.indexOf(i + 1) > -1 ? true : false
            }
            checked={
              props.combinationsString.indexOf(i + 1) > -1 ? true : false
            }
          />
          <label htmlFor={"checkbox" + i}>
            {i + 1}/{props.queue.length}
          </label>
        </div>
      );
    }
    return defaultCheckElements;
  };

  return props.queue.length < props.ballsCount
    ? generateCheckBoxes(props.queue.length)
    : generateCheckBoxes(props.ballsCount);
};

export default Misc_Ticket_Combinations;
