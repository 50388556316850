import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { VerifyTicketPost } from "../../Workers/API_Manager";
import ModalVerify from "../Loto/Ticket/ModalVerify";

const Misc_HistoryRow = (props) => {
  const timerHelper = (lotteryDate) => {
    let dt = new Date(lotteryDate);
    return dt.toLocaleTimeString().length === 11
      ? dt.toLocaleTimeString().substr(0, 5)
      : dt.toLocaleTimeString().substr(0, 4);
  };

  const [verifResponse, setVerifResponse] = useState(null);
  const [handleModal, setHandleModal] = useState(false);
  const handleOpenModal = () => setHandleModal(true);
  const handleCloseModal = () => setHandleModal(false);

  return (
    <>
      <div
        className="profile_history__row"
        onClick={() => {
          VerifyTicketPost(props.row.serial, setVerifResponse);
          handleOpenModal();
        }}
      >
        <div className="profile_history-status __lb1">
          <FontAwesomeIcon
            icon={props.icon}
            className={
              props.icon === faTimesCircle
                ? "profile_history-status-icon-x"
                : props.icon === faCheckCircle
                ? "profile_history-status-icon-v"
                : "profile_history-status-icon-w"
            }
            width="45px"
            height="45px"
          />
          <div className="profile_history-status-date">
            {new Date(props.row.ticketTime).toLocaleTimeString() +
              "," +
              timerHelper(props.row.ticketTime)}
          </div>
        </div>
        <div className="profile_history-tip __lb2">LOTTO/SYSTEM</div>
        <div className="profile-history-cod-bilet __lb3">
          {props.row.serial}
        </div>
        <div className="profile-history-cota __lb4">{props.row.odds}</div>
        <div className="profile_history-mize __lb5">
          {props.row.stake}.00 <span>{props.row.ccy}</span>
        </div>
        <div className="profile_history-plata __lb6">
          {props.row.payment != 0 ? props.row.payment : "-"}{" "}
          <span>{props.row.payment != 0 ? props.row.ccy : null}</span>
        </div>
      </div>
      {verifResponse != null && (
        <ModalVerify
          response={verifResponse}
          open={handleModal}
          onclose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Misc_HistoryRow;
