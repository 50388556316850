import React, { useEffect, useState } from "react";
import Navigation from "./Navigation/Navigation";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { InitToken } from "./Workers/TokenProcessor";
const Layout = (props) => {
  const [loader, setLoader] = useState(false);
  let idxx = 0;
  useEffect(() => {
    Promise.resolve(InitToken())
      .then((val) => localStorage.setItem("_tkn", val))
      .then(() => setLoader(true));
  }, []);

  return (
    <div className="layout_root">
      <Router>
        <div className="layout_navheader">
          <Navigation />
        </div>
        <div className="blanker"></div>
        <div className="layout_children">
          <Switch>{loader && props.children}</Switch>
        </div>
      </Router>
    </div>
  );
};

export default Layout;
