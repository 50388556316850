import React, { useEffect, useState } from "react";
import { DateToDdMmYy } from "../Workers/Convertors";

const Misc_Date = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    setInterval(() => {
      setDate(new Date());
    }, 10000);
  }, []);

  return <span>{DateToDdMmYy(date)}</span>;
};

export default Misc_Date;
