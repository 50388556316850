import { createContext, useEffect, useState } from "react";
import "./App.css";
import { Route } from "react-router-dom";
import Layout from "./Layout";
import Loto from "./Pages/Loto/Loto";
import Register from "./Pages/Register/Register";
import Results from "./Pages/Results/Results";
import Sport from "./Pages/Sport/Sport";
import { InitToken } from "./Workers/TokenProcessor";
import Profile from "./Pages/Profile/Profile";
import VerifyTicket from "./Pages/VerifyTicket/VerifyTicket";
import { EveryMatrixAuth, EveryMatrix_GetBalance } from "./Workers/API_Manager";
import * as fr from "../src/langs/fr.json";
import * as en from "../src/langs/en.json";

export const LayoutContext = createContext(null);

function App() {
  const [tkn, setTkn] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);

  const [clientId, setClientId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [launchToken, setLaunchToken] = useState(null);

  const [launchAuthResponse, setLaunchAuthResponse] = useState(null);

  const [EMuserId, setEMuserId] = useState(null);
  const [EMtoken, setEMtoken] = useState(null);
  const [EMusername, setEMusername] = useState(null);
  const [EMccy, setEMccy] = useState(null);

  const [lang, setLang] = useState(null);
  //GoTo Helpers.js for createJson

  useEffect(() => {
    if (!sessionStorage.getItem("_isLogged")) {
      Promise.resolve(InitToken()).then((val) => setTkn(val));
      localStorage.clear();
    }

    let x1 = queryParams.get("clientID");
    let x2 = queryParams.get("clientSecret");
    let x3 = queryParams.get("token");
    let x4 = queryParams.get("lang");
    if (x1 != null) setClientId(x1);
    if (x2 != null) setClientSecret(x2);
    if (x3 != null) setLaunchToken(x3);
    if (x4 === "fr" || x4 === "FR") setLang(fr);
    else setLang(en);
  }, []);

  useEffect(() => {
    localStorage.setItem("_tkn", tkn);
  }, [tkn]);

  // useEffect(() => {
  //   if (cliendId != null) console.log(cliendId);
  //   if (clientSecret != null) console.log(clientSecret);
  //   if (launchToken != null) console.log(launchToken);
  // }, [cliendId]);



  useEffect(() => {
    if (launchToken != null) {
      EveryMatrixAuth(
        launchToken,
        setLaunchAuthResponse,
        setEMtoken,
        setEMusername,
        setEMuserId,
        setEMccy
      );
    }
  }, [launchToken]);

  const layoutValue = {
    launchAuthResponse: [launchAuthResponse, setLaunchAuthResponse],
    EMuserId: [EMuserId, setEMuserId],
    EMtoken: [EMtoken, setEMtoken],
    EMusername: [EMusername, setEMusername],
    EMccy: [EMccy, setEMccy],
    lang: [lang, setLang],
  };

  return (
    <LayoutContext.Provider value={layoutValue}>
      <Layout>
        <Route exact path="/" component={Loto} />
        <Route exact path="/live" component={Sport} />
        <Route exact path="/casino" component={Sport} />
        <Route exact path="/loto" component={Loto} />
        <Route exact path="/virtuale" component={Sport} />
        <Route exact path="/rezultate" component={Results} />
        <Route exact path="/ajutor" component={Sport} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/verifyTicket" component={VerifyTicket} />
      </Layout>
    </LayoutContext.Provider>
  );
}

export default App;
