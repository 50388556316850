import React, { useContext } from "react";
import { LayoutContext } from "../../../App";
import {
  GetContentByCountry,
  GetDailyContent,
  GetResultsContentByCoutry,
} from "../../../Workers/API_Manager";
import { createFlag } from "../../../Workers/Helpers";
import { ResultsContext } from "../../Results/Results";
import { LotoContext } from "../Loto";

const Misc_Generic_All_Country = (props) => {
  const consumer = useContext(LotoContext);
  const resultConsumer = useContext(ResultsContext);

  const lang = useContext(LayoutContext);

  const ConditionalFetcher = (whatFor) => {
    if (whatFor === "lotto") {
      GetContentByCountry(
        consumer.dateSelector[0].indexOf(1),
        "",
        consumer.dailyContent[1]
      );
    } else {
      GetResultsContentByCoutry(
        resultConsumer.calendarSelector[0].indexOf(1),
        "",
        resultConsumer.resultsDailyContent[1]
      );
    }
  };
  return (
    <div className="all_button_country">
      <div
        className={
          props.openId === "home"
            ? "all_button_country_item all_button_country_item_active"
            : "all_button_country_item"
        }
        onClick={() => {
          props.toggleOpenId("home");
          ConditionalFetcher(props.whatFor);
        }}
      >
        <img
          className="all_button_icon"
          src={createFlag("home")}
          width="18px"
          height="18px"
        />
        <span className="all_button_span">{lang.lang[0].default.Menu_3}</span>
      </div>
    </div>
  );
};

export default Misc_Generic_All_Country;
