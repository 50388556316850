import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { LayoutContext } from "../App";

const Misc_Modal_Login_Forms = (props) => {
  const history = useHistory();

  const lang = useContext(LayoutContext);

  return (
    <div className="modal-forms">
      <form className="form-group">
        <div className="form-field-user">
          <label className="input-label">{lang.lang[0].default.Login_2}</label>
          <div className="input-field-box">
            <input
              className="input-field"
              placeholder={lang.lang[0].default.Login_3}
              value={props.user}
              onChange={(e) => props.handleUserChange(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="form-field-user pass-input">
          <label className="input-label">
            <div>{lang.lang[0].default.Login_4}</div>
            <div className="forgot-pass">{lang.lang[0].default.Login_5}</div>
          </label>
          <input
            className="input-field"
            type="password"
            autoComplete
            placeholder={lang.lang[0].default.Login_4}
            value={props.pass}
            onChange={(e) => props.handlePassChange(e.target.value)}
          ></input>
        </div>
      </form>
      {props.errorMessage != null && (
        <div className="login_failed_err_msg">{props.errorMessage}</div>
      )}
      <button
        type="submit"
        className="modal-login-button"
        onClick={(e) => {
          props.handleLoginHere(props.user, props.pass);
          e.preventDefault();
        }}
      >
        {lang.lang[0].default.Login_6}
      </button>
    </div>
  );
};

export default Misc_Modal_Login_Forms;
