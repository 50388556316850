import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGreaterThan } from "@fortawesome/fontawesome-free-solid";
import { VerifyTicketPost } from "../../../Workers/API_Manager";
import ModalVerify from "./ModalVerify";
import { LayoutContext } from "../../../App";

const Misc_Ticket_Verification = () => {
  const [code, setCode] = useState(null);
  const [verifResponse, setVerifResponse] = useState(null);
  const [showVerifModal, setShowVerifModal] = useState(false);

  const handleOpenModal = () => setShowVerifModal(true);
  const handleCloseModal = () => setShowVerifModal(false);

  const handleInputChange = (newVal, setter) => {
    setter(newVal);
  };

  const lang = useContext(LayoutContext);

  return (
    <>
      <div className="ticket_verify_root">
        <div className="ticket_verify_left">
          <label className="ticket_verify_label">
            {lang.lang[0].default.Verify_1}
          </label>
        </div>
        <div className="ticket_verify_right">
          <form>
            <input
              type="text"
              placeholder={lang.lang[0].default.Verify_2}
              id="ticketId"
              className="ticket_verify_intup"
              onChange={(e) => {
                handleInputChange(e.target.value, setCode);
              }}
            />
            <button
              type="button"
              className="ticket_verify_button"
              onClick={() => {
                VerifyTicketPost(code, setVerifResponse);
                handleOpenModal();
              }}
            >
              <FontAwesomeIcon
                icon={faGreaterThan}
                className="material-icons"
              />
            </button>
          </form>
        </div>
      </div>
      {verifResponse != null && (
        <ModalVerify
          response={verifResponse}
          open={showVerifModal}
          onclose={handleCloseModal}
        />
      )}
    </>
  );
};

export default Misc_Ticket_Verification;
