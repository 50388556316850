import React, { useContext } from "react";
import { fromStringToArray } from "../../../Workers/Convertors";
import { ResultsContext } from "../../Results/Results";
import { LotoContext } from "../Loto";
import Misc_TopIdx_SingleElement from "./Misc_TopIdx_SingleElement";

const Misc_TopIdx = (props) => {
  const consumer = useContext(LotoContext);
  const resConsumer = useContext(ResultsContext);
  const topLotteries =
    props.whatFor === "lotto"
      ? consumer.topIdxLotteries[0]
      : resConsumer.topIdxLotteries[0];
  return (
    <>
      {topLotteries &&
        topLotteries.map((element, key) => {
          return (
            <div key={key}>
              <Misc_TopIdx_SingleElement
                countryCode={element.imgKey}
                lotteryName={element.name}
                drawBalls={fromStringToArray(element.system, "/")[0]}
                ballsCount={fromStringToArray(element.system, "/")[1]}
                id={element.id}
                whatFor={props.whatFor}
                hasCamera={element.liveCast}
              />
            </div>
          );
        })}
    </>
  );
};

export default Misc_TopIdx;
