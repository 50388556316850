import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClipboardList,
  faHourglassHalf,
} from "@fortawesome/fontawesome-free-solid";
import Misc_OngoingRows from "./Misc_OngoingRows";
import Misc_FinishedRows from "./Misc_FinishedRows";
import { LayoutContext } from "../../App";
const Misc_PlayerTickets = () => {
  const [selectedDate, setSelectedDate] = useState([1, 0, 0]);
  const [ticketHistoryData, setTicketHistoryData] = useState([]);
  const [loadTicketHistoryData, setLoadedHistoryData] = useState(false);
  const [onGoing, setOnGoing] = useState(null);
  const [decided, setDecided] = useState(null);

  const lang = useContext(LayoutContext);

  const handleDateChange = (index) => {
    let auxArr = [0, 0, 0];
    auxArr[index] = 1;
    setSelectedDate(auxArr);
  };

  useEffect(() => {
    let ong = [];
    let decd = [];
    ticketHistoryData.length > 0 &&
      ticketHistoryData.forEach((element) => {
        if (element.status === 0 || element.status === 1) {
          decd.push(element);
        } else ong.push(element);
      });
    setOnGoing(ong);
    setDecided(decd);
  }, [ticketHistoryData]);

  useEffect(() => {
    console.log(decided);
  }, [decided]);

  const getHistoryByDay = async (day) => {
    const tkn = localStorage.getItem("_tknLog");
    const config = {
      headers: { Authorization: `Bearer ${tkn}` },
    };

    const response = await axios(
      process.env.REACT_APP_TICKET_HISTORY + `${day}`,
      config
    );
    setTicketHistoryData(response.data);
  };
  useEffect(() => {
    getHistoryByDay(0);
    setLoadedHistoryData(true);
  }, []);

  const createOnGoingComponent = () => {
    return (
      <>
        <div className="player-t-ongoing-header">
          <FontAwesomeIcon
            className="player-t-o_h-icon"
            icon={faHourglassHalf}
          />
          <div className="player-t-o_h-label">
            {lang.lang[0].default.Profile_16}
          </div>
        </div>
        <div className="player-t-ongoing-subheader">
          <div className="player-t__event-label __lb1">
            {lang.lang[0].default.Profile_17}
          </div>
          <div className="player-t__event-label __lb2">
            {lang.lang[0].default.Profile_18}
          </div>
          <div className="player-t__event-label __lb3">
            {lang.lang[0].default.Profile_19}
          </div>
          <div className="player-t__event-label __lb4">
            {lang.lang[0].default.Profile_20}
          </div>
          <div className="player-t__event-label __lb5">
            {lang.lang[0].default.Profile_21}
          </div>
          <div className="player-t__event-label __lb6">
            {lang.lang[0].default.Profile_22}
          </div>
        </div>
        <div className="player-t-ongoing-content">
          {onGoing && <Misc_OngoingRows historyTicketResponse={onGoing} />}
        </div>
      </>
    );
  };
  const createFinishedComponent = () => {
    return (
      <>
        <div className="player-t-ongoing-header">
          <FontAwesomeIcon className="player-t-o_h-icon" icon={faCheckCircle} />
          <div className="player-t-o_h-label">
            {lang.lang[0].default.Profile_23}
          </div>
        </div>
        <div className="player-t-ongoing-subheader">
          <div className="player-t__event-label __lb1">
            {lang.lang[0].default.Profile_24}
          </div>
          <div className="player-t__event-label __lb2">
            {lang.lang[0].default.Profile_18}
          </div>
          <div className="player-t__event-label __lb3">
            {lang.lang[0].default.Profile_19}
          </div>
          <div className="player-t__event-label __lb4">
            {lang.lang[0].default.Profile_20}
          </div>
          <div className="player-t__event-label __lb5">
            {lang.lang[0].default.Profile_21}
          </div>
          <div className="player-t__event-label __lb6">
            {lang.lang[0].default.Profile_25}
          </div>
        </div>
        <div className="player-t-ongoing-content">
          {decided && <Misc_FinishedRows historyTicketResponse={decided} />}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="player-tickets-root">
        <div className="player-tickets-dateSelector-btns">
          <div
            className={
              selectedDate.indexOf(1) === 0
                ? "player-ticket-date-selector-btn is_date_active"
                : "player-ticket-date-selector-btn"
            }
            onClick={() => handleDateChange(0)}
          >
            {lang.lang[0].default.Profile_12}
          </div>
          <div
            className={
              selectedDate.indexOf(1) === 1
                ? "player-ticket-date-selector-btn is_date_active"
                : "player-ticket-date-selector-btn"
            }
            onClick={() => handleDateChange(1)}
          >
            {lang.lang[0].default.Profile_13}
          </div>
          <div
            className={
              selectedDate.indexOf(1) === 2
                ? "player-ticket-date-selector-btn is_date_active"
                : "player-ticket-date-selector-btn"
            }
            onClick={() => handleDateChange(2)}
          >
            {lang.lang[0].default.Profile_14}
          </div>
        </div>
        <div className="player-tickets-header">
          <FontAwesomeIcon
            className="player-tickets-label-icon"
            icon={faClipboardList}
          />
          <div className="player-tickets-label">
            {lang.lang[0].default.Profile_15}
          </div>
        </div>
        <div className="player-tickets-ongoing">{createOnGoingComponent()}</div>
        <div className="player-tickets-finished">
          {createFinishedComponent()}
        </div>
      </div>
    </>
  );
};

export default Misc_PlayerTickets;
