import React from "react";
import { Modal } from "@material-ui/core";
import Misc_Modal_Login_Header from "./Misc_Modal_Login_Header";
import Misc_Modal_Login_Forms from "./Misc_Modal_Login_Forms";
import Misc_Modal_Login_Buttons from "./Misc_Modal_Login_Buttons";

const ModalLogin = (props) => {
  return (
    <Modal open={props.open} onClose={props.onclose} className="modal_login">
      <div className="modal_login_container">
        <Misc_Modal_Login_Header onclose={props.onclose} />
        <div className="modal_login_body">
          <Misc_Modal_Login_Forms
            user={props.user}
            handleUserChange={props.handleUserChange}
            pass={props.pass}
            handlePassChange={props.handlePassChange}
            handleLoginHere={props.handleLoginHere}
            errorMessage={props.errorMessage}
          />
          <Misc_Modal_Login_Buttons />
        </div>
      </div>
    </Modal>
  );
};

export default ModalLogin;
