import React from "react";
import Misc_GenericSideMenu from "../Loto/SideMenu/Misc_GenericSideMenu";
import Misc_TopIdx from "../Loto/SideMenu/Misc_TopIdx";

const SideMenuResults = () => {
  return (
    <div className="side_menu_root">
      <div className="side_menu_top_idx">
        <Misc_TopIdx />
      </div>
      <div className="side_menu_list">
        <Misc_GenericSideMenu />
      </div>
    </div>
  );
};

export default SideMenuResults;
