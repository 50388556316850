import React, { useContext, useEffect, useState } from "react";
import { GetContentByLottery } from "../../../Workers/API_Manager";
import { addFavToCookies, getCookie } from "../../../Workers/Helpers";
import { LotoContext } from "../Loto";

const Misc_Favorites_Items = (props) => {
  const [over, setOver] = useState(false);
  const consumer = useContext(LotoContext);
  const [ids, setIds] = useState([]);

  useEffect(() => {
    let currentValues = getCookie("lvCast");
    let x = currentValues.split("-");
    setIds(x);
  }, []);

  const checkIfInCookie = (id) => {
    let currentValues = getCookie("lvCastName");
    let x = currentValues.split("-");
    if (x.indexOf(id.toString()) != -1) {
      return true;
    } else {
      return false;
    }
  };
  const removeFromFavs = (favId, cookieName) => {
    let string = getCookie(cookieName);
    if (string.charAt(0) === "-") {
      let x = string.substring(1, string.length);
      x.replace(favId, "");
      addFavToCookies(cookieName, x);
    } else {
      let y = string.replace(favId, "");
      if (y.charAt(0) === "-") {
        y = y.substring(1, y.length);
      }
      if (y.charAt(y.length - 1) === "-") {
        y = y.substring(0, y.length - 1);
      }
      // if (y.charAt(0) != "-" && y.charAt(y.length) != "-") {
      //   let index = y.indexOf("-");
      //   y = y.replace(index, "");
      // }
      y = y.replace("--", "-");
      addFavToCookies(cookieName, y);
    }
  };

  return props.lotteries.map((element, key) => {
    return (
      <div
        className="generic_country_subitem"
        key={key}
        onClick={() => {
          GetContentByLottery(
            consumer.dateSelector[0].indexOf(1),
            ids[props.lotteries.indexOf(element)],
            consumer.dailyContent[1]
          );
        }}
      >
        <div className="generic_subitem_spaning">{element}</div>
        <img
          src={
            checkIfInCookie(element)
              ? "full_star.png"
              : over
              ? "full_star.png"
              : "empty_star.png"
          }
          width="21px"
          height="20px"
          className="generic_subitem_camera"
          onMouseOver={() => setOver(true)}
          onMouseOut={() => setOver(false)}
          onClick={(e) => {
            e.stopPropagation();
            removeFromFavs(element, "lvCastName");
            removeFromFavs(ids[props.lotteries.indexOf(element)], "lvCast");
          }}
        />
      </div>
    );
  });
};

export default Misc_Favorites_Items;
