import React from "react";

const SideTicket = (props) => {
  const formatQueueForTicketMobile = (queueString) => {
    if (queueString.length < 15) {
      return queueString;
    } else {
      return queueString.substr(0, 13) + "...";
    }
  };

  return (
    <div
      className="side_ticket_root"
      onClick={() => {
        props.toggleTogglerTicket();
      }}
    >
      <div className="side_ticket_queue_label">Selected Numbers</div>
      <div className="side_ticket_betslip_label">Amount</div>
      <div className="side_ticket_potwin_label">Potential Win</div>
      <div className="side_ticket_queue">
        {formatQueueForTicketMobile(props.queue.join(","))}
      </div>
      <div className="side_ticket_betslip">{props.stakeValue}</div>
      <div className="side_ticket_potwin">
        {props.postRequste != null ? props.postRequste?.maxWin : "0.00"}
      </div>
    </div>
  );
};

export default SideTicket;
