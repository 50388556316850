import React from "react";
import {
  convertUtcToSingleRowTime,
  fromStringToArray,
} from "../../../Workers/Convertors";
import "../ResultsGrid.css";

const Misc_ContentResults_Single_Row = (props) => {
  let drawsArray = fromStringToArray(props.lottery.draws, ",");
  return (
    <div className="content_results_single_row_root">
      <div className="content_results_single_row_date">
        {convertUtcToSingleRowTime(props.lottery.date)}
      </div>
      <div className="content_results_single_row_label">
        <div className="results_single_row_name">
          {props.lottery.lotteryName}
        </div>
        <div className="results_single_row_system">
          <div className="results_single_row_guess">
            {fromStringToArray(props.lottery.system, "/")[0]}
          </div>
          <div className="results_single_row_outof">
            {fromStringToArray(props.lottery.system, "/")[1]}
          </div>
        </div>
      </div>
      <div className="content_results_single_row_numbers">
        {drawsArray.map((element, key) => {
          return (
            <div className="content_results_single_row_num" key={key}>
              {element}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Misc_ContentResults_Single_Row;
