import {
  faTrashAlt,
  faTimesCircle,
  faArrowDown,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../App";
import { LotoContext } from "../Loto";

const Misc_Ticket_Header = (props) => {
  const consumer = useContext(LotoContext);
  const lang = useContext(LayoutContext);
  return (
    <>
      <div className="ticket_header_text_text">
        <div className="ticket_header_text">
          {lang.lang[0].default.Ticket_1}
        </div>
        {props.verif === false && (
          <FontAwesomeIcon
            icon={faArrowDown}
            onClick={() => {
              props.setTogglerTicket(false);
            }}
          />
        )}
      </div>
      {consumer.errMsg[0] != null && (
        <div className="ticket_errors_and_stuff">
          {consumer.errMsg[0]}
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ cursor: "pointer", marginLeft: "10px" }}
            onClick={() => {
              consumer.errMsg[1](null);
            }}
          />
        </div>
      )}
      <div className="ticket_subheader">
        <div className="ticket_subheader_infos">
          <div className="ticket_subheader_infos_text">
            {lang.lang[0].default.Ticket_2}:{" "}
          </div>
          <div className="ticket_subheader_infos_value">
            {props.queue.length}
          </div>
        </div>
        <div className="ticket_subheader_actions">
          <button
            className="ticket_subheader_clear"
            onClick={() => {
              props.setQueue([]);
            }}
          >
            <span>{lang.lang[0].default.Ticket_3}</span>
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="ticket_subheader_icontrash"
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default Misc_Ticket_Header;
