import React, { useContext, useState } from "react";
import { faSync } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LotoContext } from "../Loto";
import { fromStringToArray } from "../../../Workers/Convertors";
import { LayoutContext } from "../../../App";

const Misc_PlayTable_Number_Generator = () => {
  const consumer = useContext(LotoContext);

  const lang = useContext(LayoutContext);

  const [counter, setCounter] = useState(5);
  const handlePlus = () => {
    if (counter < consumer.lotteryDetails[0]?.lottery.maxBetNumbers) {
      setCounter(counter + 1);
    }
  };
  const handleMinus = () => {
    if (counter > 0) {
      setCounter(counter - 1);
    }
  };

  const randomGenerator = () => {
    let arr = [];
    let nums = fromStringToArray(
      consumer.lotteryDetails[0]?.lottery.system,
      "/"
    )[1];
    consumer.queue[1]([]);
    while (arr.length < counter) {
      var r = Math.floor(Math.random() * nums) + 1;
      if (arr.indexOf(r) === -1) arr.push(r);
    }
    consumer.queue[1](arr);
  };

  return (
    <div className="playtable_num_generator_root">
      <div className="playtable_num_gen_buttons">
        <div className="playtable_num_gen_btns_header">
          {lang.lang[0].default.Palette_4}
        </div>
        <div className="playtable_num_gen_btns_wheel">
          <button
            className="playtable_num_gen_minus"
            onClick={() => handleMinus()}
          >
            -
          </button>
          <div className="playtable_num_gen_value">{counter}</div>
          <button
            className="playtable_num_gen_plus"
            onClick={() => handlePlus()}
          >
            +
          </button>
        </div>
        <button
          className="playtable_num_gen_action"
          onClick={() => randomGenerator()}
        >
          <FontAwesomeIcon className="playtable_num_gen_icon" icon={faSync} />
          <span>{lang.lang[0].default.Palette_5}</span>
        </button>
      </div>
    </div>
  );
};

export default Misc_PlayTable_Number_Generator;
