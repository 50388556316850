import {
  faAngleDoubleUp,
  faLevelUpAlt,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../../../App";
import { LotoContext } from "../Loto";

const Misc_PlayTable_Header = (props) => {
  const [timer, setTimer] = useState();
  const consumer = useContext(LotoContext);
  const timerStarterMins = 5;

  const lang = useContext(LayoutContext);

  const msToMMss = (time) => {
    if (time > 0) {
      let sec = time / 1000;
      let minutes = parseInt(sec / 60);
      let seconds = (sec % 60).toFixed(0);
      minutes = minutes >= 10 ? minutes : "0" + minutes;
      seconds = seconds >= 10 ? seconds : "0" + seconds;

      return minutes + ":" + seconds;
    }
  };

  useEffect(() => {
    setInterval(() => {
      let curentDate = new Date();
      let lotoDate = new Date(props.date);
      setTimer(lotoDate.getTime() - curentDate.getTime());
    }, 1000);
  }, []);

  return (
    <div
      className="play_table_header_root"
      onClick={(e) => {
        e.stopPropagation();
        props.toggleplay();
      }}
    >
      <div className="play_table_header_lbl">
        {lang.lang[0].default.Palette_1}
      </div>
      <div className="play_table_time_left" style={{ color: "red" }}>
        {props.timer < timerStarterMins * 60 * 1000 && msToMMss(props.timer)}
      </div>
      <div className="play_table_up_arrow">
        <FontAwesomeIcon
          icon={faAngleDoubleUp}
          style={{ width: "20px", height: "20px", verticalAlign: "middle" }}
        />
      </div>
      <div className="play_table_max_nums">
        {lang.lang[0].default.Palette_2}{" "}
        {consumer.lotteryDetails[0]?.lottery.maxBetNumbers}{" "}
        {lang.lang[0].default.Palette_3}
      </div>
    </div>
  );
};

export default Misc_PlayTable_Header;
