import { faHourglassStart } from "@fortawesome/fontawesome-free-solid";
import React from "react";
import Misc_HistoryRow from "./Misc_HistoryRow";

const Misc_OngoingRows = (props) => {
  return (
    <>
      {props.historyTicketResponse.length > 0 &&
        props.historyTicketResponse.map((element, key) => {
          return (
            <div key={key}>
              <Misc_HistoryRow row={element} icon={faHourglassStart} />
            </div>
          );
        })}
    </>
  );
};

export default Misc_OngoingRows;
