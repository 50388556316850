import { faTimes } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { LotoContext } from "../Loto";
import { Modal } from "@material-ui/core";
import "./ModalTicket.css";
import { convertUtcToTicketTime } from "../../../Workers/Convertors";
import { LayoutContext } from "../../../App";

const ModalTicket = (props) => {
  const consumer = useContext(LotoContext);
  var Barcode = require("react-barcode");

  const lang = useContext(LayoutContext);

  const generateNumbersList = () => {
    return props.queue.map((element, key) => {
      return (
        <div className="ticket_ticket_ball" key={key}>
          <span>{element}</span>
        </div>
      );
    });
  };

  window.onafterprint = () => {
    let xxx = document.querySelector(
      "body > div.modal-ticket > div.modal-container > div.modal-ticket-content > div.modal-ticket-right > div:nth-child(1) > div > svg > rect"
    );
    xxx.style.cssText = " fill: rgb(46, 46, 46) !important";
    let yyy = document.querySelector(
      "body > div.modal-ticket > div.modal-container > div.modal-ticket-content > div.modal-ticket-right > div:nth-child(1) > div > svg > g"
    );
    yyy.style.cssText = "fill: #999 !important";
  };

  return (
    <Modal open={props.open} onClose={props.onclose} className="modal-ticket">
      <div className="modal-container">
        <div className="modal-ticket-header">
          <div className="modal-ticket-header-title">
            {lang.lang[0].default.Verif_1}
          </div>
          <FontAwesomeIcon
            icon={faTimes}
            className="modal-ticket-header-icon"
            onClick={props.onclose}
          />
        </div>
        <div className="modal-ticket-subheader">
          <div className="modal-ticket-subheader-numbers">
            {lang.lang[0].default.Verif_2}:
            <span>{consumer.queue[0].length}</span>
          </div>
          <div className="modal-ticket-subheader-cota">
            {lang.lang[0].default.Verif_3}:
            <span>{consumer.bookResponse[0].ticket?.odds}</span>
          </div>
        </div>
        <div className="modal-ticket-content">
          <div className="modal-ticket-left-wrap">
            <div className="modal-ticket-left">
              <div className="modal-ticket-date-time">
                {convertUtcToTicketTime(
                  consumer.bookResponse[0].ticket?.header?.date
                )}
              </div>
              <div className="modal-ticket-name">
                {consumer.bookResponse[0].ticket?.content?.lottery}
              </div>
              <div>{generateNumbersList()}</div>
            </div>
          </div>

          <div className="modal-ticket-right">
            <div
              style={{
                fontSize: "10px",
                color: "darkgrey",
                textAlign: "center",
              }}
            >
              <div className="modal-ticket-serial">
                <Barcode
                  value={
                    consumer.bookResponse[0] != null
                      ? consumer.bookResponse[0].ticket.header.serial
                      : ""
                  }
                  format="CODE128"
                  textPosition="top"
                />
              </div>
            </div>
            <div className="modal-ticket-mize">
              <div className="modal-ticket-mize-ct">
                <span
                  className="modal-ticket-mize-ct-ct"
                  style={{ fontWeight: "300" }}
                >
                  {lang.lang[0].default.Verif_4}:
                </span>
                <span className="modal-ticket-mize-ct-num">
                  {consumer.bookResponse[0].ticket?.odds}
                </span>
              </div>

              <div className="modal-ticket-mize-miza">
                <span
                  className="modal-ticket-mize-ct-ct"
                  style={{ fontWeight: "300" }}
                >
                  {lang.lang[0].default.Verif_5}:
                </span>
                <span className="modal-ticket-mize-ct-num">
                  {consumer.bookResponse[0] != null
                    ? consumer.bookResponse[0].ticket.content.system
                    : null}
                </span>
              </div>

              <div className="modal-ticket-mize-miza">
                <span
                  className="modal-ticket-mize-ct-ct"
                  style={{ fontWeight: "300" }}
                >
                  {lang.lang[0].default.Verif_6}:
                </span>
                <span className="modal-ticket-mize-ct-num">
                  {consumer.bookResponse[0] != null
                    ? consumer.bookResponse[0].ticket.header.paid.gross
                    : "0.00"}
                  <span> {consumer.bookResponse[0].ticket.header.ccy}</span>
                </span>
              </div>

              {consumer.bookResponse[0].ticket.header.paid.tax != null && (
                <div className="modal-ticket-mize-taxa">
                  <span
                    className="modal-ticket-mize-ct-ct"
                    style={{ fontWeight: "300" }}
                  >
                    CDA TAX:
                  </span>
                  <span className="modal-ticket-mize-ct-num">
                    {consumer.bookResponse[0] != null
                      ? consumer.bookResponse[0].ticket.header.paid.tax ??
                        "0.00"
                      : "0.00"}
                    <span>{consumer.bookResponse[0].ticket.header.ccy}</span>
                  </span>
                </div>
              )}
              <div className="modal-ticket-mize-mizataxa">
                <span
                  className="modal-ticket-mize-ct-ct"
                  style={{ fontWeight: "300" }}
                >
                  {lang.lang[0].default.Verif_7}:
                </span>
                <span className="modal-ticket-mize-ct-num">
                  {consumer.bookResponse[0] != null
                    ? consumer.bookResponse[0].ticket.header.paid.net
                    : "0.00"}
                  <span> {consumer.bookResponse[0].ticket.header.ccy}</span>
                </span>
              </div>

              {consumer.bookResponse[0] != null ? (
                consumer.postRequste[0].minWin ? (
                  <div className="modal-ticket-mize-mizataxa">
                    <span
                      className="modal-ticket-mize-ct-ct"
                      style={{ fontWeight: "300" }}
                    >
                      Minimum Win:
                    </span>
                    <span className="modal-ticket-mize-ct-num">
                      {consumer.postRequste[0].minWin}{" "}
                      <span>{consumer.bookResponse[0].ticket.header.ccy}</span>
                    </span>
                  </div>
                ) : null
              ) : null}
            </div>
            <div className="modal-ticket-buttons">
              <div className="modal-ticket-pot-win">
                <div
                  className="modal-ticket-pot-win-lbl"
                  style={{ fontWeight: "300" }}
                >
                  MAX WIN:
                </div>
                <div className="modal-ticket-pot-win-win">
                  {consumer.bookResponse[0] != null
                    ? consumer.postRequste[0].maxWin
                    : "0.00"}
                  <span>{consumer.bookResponse[0].ticket.header.ccy}</span>
                </div>
              </div>
              <div className="modal-tickets-btns">
                <button
                  className="modal-ticket-close-btn"
                  onClick={props.onclose}
                >
                  {lang.lang[0].default.Verif_9}
                </button>
                <button
                  className="modal-ticket-print-btn"
                  onClick={() => {
                    let xxx = document.querySelector(
                      "body > div.modal-ticket > div.modal-container > div.modal-ticket-content > div.modal-ticket-right > div:nth-child(1) > div > svg > rect"
                    );
                    xxx.style.cssText = "fill: white !important";
                    let yyy = document.querySelector(
                      "body > div.modal-ticket > div.modal-container > div.modal-ticket-content > div.modal-ticket-right > div:nth-child(1) > div > svg > g"
                    );
                    yyy.style.cssText = "fill: black !important";
                    window.print();
                  }}
                >
                  {lang.lang[0].default.Verif_10}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTicket;
