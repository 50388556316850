import {
  faCalendarAlt,
  faHome,
  faSlidersH,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import SideMenu from "../SideMenu";
import "./Mobile.css";

const SideMobile = (props) => {
  return (
    <>
      <div className="side_mobile_root">
        <div
          className="side_mobile_lottery_buttons"
          onClick={() => {
            props.toggleToggler();
          }}
        >
          <div className="side_mobile_lottery_label">
            <FontAwesomeIcon
              className="side_lottery_label_icon"
              icon={faHome}
            />
            <div className="side_lottery_label_label">ALL LOTTERIES</div>
          </div>
          <FontAwesomeIcon
            className="side_mobile_lottery_iconify"
            icon={faSlidersH}
          />
        </div>
        <div
          className="side_mobile_date_selector"
          onClick={() => {
            props.toggleTogglerDate();
          }}
        >
          <FontAwesomeIcon
            className="side_mobile_date_icon"
            icon={faCalendarAlt}
          />
          <div className="side_mobile_date_label">Today</div>
        </div>
      </div>
    </>
  );
};

export default SideMobile;
