import {
  faArrowLeft,
  faClipboardList,
  faGift,
  faHandHoldingUsd,
  faHandPeace,
  faHistory,
  faMoneyBillWave,
  faSignOutAlt,
  faUpload,
  faUser,
  faUserCog,
} from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { LayoutContext } from "../../App";

const Misc_ProfileSide = (props) => {
  const changeActiveValueArray = (index) => {
    let auxArr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    auxArr[index] = 1;
    props.setActiveItem(auxArr);
  };
  const history = useHistory();
  const logout = () => {
    sessionStorage.removeItem("_isLogged");
  };

  const lang = useContext(LayoutContext);

  return (
    <div className="profile-side-root">
      <div className="nav-sider-wrapper">
        <div className="nav-sider-tree">
          <li
            className="nav-sider-tree-item mobile_bordered"
            onClick={() => {
              changeActiveValueArray(0);
            }}
          >
            <FontAwesomeIcon
              className="nav-sider-tree-item-icon"
              icon={faArrowLeft}
            />
            <div className="nav-sider-tree-item-label">
              {lang.lang[0].default.Profile_1}
            </div>
          </li>
          <li
            className="nav-sider-tree-item"
            onClick={() => {
              changeActiveValueArray(1);
            }}
          >
            <FontAwesomeIcon
              className="nav-sider-tree-item-icon"
              icon={faUser}
            />
            <div className="nav-sider-tree-item-label">
              {lang.lang[0].default.Profile_2}
            </div>
          </li>
          <li
            className="nav-sider-tree-item mobile_bordered"
            onClick={() => {
              changeActiveValueArray(2);
            }}
          >
            <FontAwesomeIcon
              className="nav-sider-tree-item-icon"
              icon={faClipboardList}
            />
            <div className="nav-sider-tree-item-label">
              {lang.lang[0].default.Profile_3}
            </div>
          </li>
          <li
            className="nav-sider-tree-item"
            onClick={() => {
              changeActiveValueArray(3);
            }}
          >
            <FontAwesomeIcon
              className="nav-sider-tree-item-icon"
              icon={faHandHoldingUsd}
            />
            <div className="nav-sider-tree-item-label">
              {lang.lang[0].default.Profile_4}
            </div>
          </li>
          <li
            className="nav-sider-tree-item mobile_bordered"
            onClick={() => {
              changeActiveValueArray(4);
            }}
          >
            <FontAwesomeIcon
              className="nav-sider-tree-item-icon"
              icon={faMoneyBillWave}
            />
            <div className="nav-sider-tree-item-label">
              {lang.lang[0].default.Profile_5}
            </div>
          </li>
          <li
            className="nav-sider-tree-item"
            onClick={() => {
              changeActiveValueArray(5);
            }}
          >
            <FontAwesomeIcon
              className="nav-sider-tree-item-icon"
              icon={faGift}
            />
            <div className="nav-sider-tree-item-label">
              {lang.lang[0].default.Profile_6}
            </div>
          </li>
          <li
            className="nav-sider-tree-item mobile_bordered"
            onClick={() => {
              changeActiveValueArray(6);
            }}
          >
            <FontAwesomeIcon
              className="nav-sider-tree-item-icon"
              icon={faHistory}
            />
            <div className="nav-sider-tree-item-label">
              {lang.lang[0].default.Profile_7}
            </div>
          </li>
          <li
            className="nav-sider-tree-item"
            onClick={() => {
              changeActiveValueArray(7);
            }}
          >
            <FontAwesomeIcon
              className="nav-sider-tree-item-icon"
              icon={faUserCog}
            />
            <div className="nav-sider-tree-item-label">
              {lang.lang[0].default.Profile_8}
            </div>
          </li>
          <li
            className="nav-sider-tree-item mobile_bordered"
            onClick={() => {
              changeActiveValueArray(8);
            }}
          >
            <FontAwesomeIcon
              className="nav-sider-tree-item-icon"
              icon={faHandPeace}
            />
            <div className="nav-sider-tree-item-label">
              {lang.lang[0].default.Profile_9}
            </div>
          </li>
          <li
            className="nav-sider-tree-item"
            onClick={() => {
              changeActiveValueArray(9);
            }}
          >
            <FontAwesomeIcon
              className="nav-sider-tree-item-icon"
              icon={faUpload}
            />
            <div className="nav-sider-tree-item-label">
              {lang.lang[0].default.Profile_10}
            </div>
          </li>
          <NavLink to="/" exact className="nav-sider-tree-item-label">
            <li
              className="nav-sider-tree-item mobile_bordered"
              onClick={() => {
                changeActiveValueArray(10);
                logout();
                document.cookie =
                  "lvCast=;expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
                document.cookie =
                  "lvCastName=;expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
                localStorage.removeItem("_tknLog");
                history.push("/loto");
                window.location.reload();
              }}
            >
              <FontAwesomeIcon
                className="nav-sider-tree-item-icon"
                icon={faSignOutAlt}
              />
              {lang.lang[0].default.Profile_11}
            </li>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Misc_ProfileSide;
